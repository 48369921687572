import {
  Accessor,
  Component,
  For,
  Show,
  createMemo,
  createResource,
  createSignal,
  useContext
} from "solid-js";
import {
  AuthContext,
  Button,
  DeployProviderIcon,
  DialogParams,
  Dropdown,
  FaIcon,
  JobState,
  LocalizationContext,
  PrettyDataSize,
  ResourceFallback,
  callDialog,
  compareTags,
  deployProvidersInfos,
  watchJobs,
} from "../components";
import {
  DeploySiteInfo,
  PackageInfo,
  ProjectInfo,
  VersionInfo
} from "../components/api-generated";
import { AddDeploySiteDialog } from "./add_deploy_site";
import {
  showErrorScope,
} from "./basic";

export const DeployToSiteDialog: Component<DialogParams<boolean> & {
  project: ProjectInfo;
  version: VersionInfo;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const { api } = useContext(AuthContext)!;

  const [deploySites, { refetch: refetchDeploySites }] = createResource(async () => {
    return await api.getProjectsDeploySites({
      project: props.project.id,
    });
  });

  const [versionPackages] = createResource(async () => {
    return await api.getProjectsVersionsPackages({
      project: props.project.id,
      version: props.version.id,
    });
  });

  const [selectedDeploySite, setSelectedDeploySite] = createSignal<DeploySiteInfo | null>(null);

  const deploySiteTitle = (deploySite: DeploySiteInfo | null) => deploySite && <>
    <DeployProviderIcon provider={deploySite.provider} />
    <span>{deployProvidersInfos[deploySite.provider].siteTitle(deploySite.site)}</span>
  </>;

  const onSelectDeploySite = async (deploySite: DeploySiteInfo | null) => {
    if(deploySite) {
      setSelectedDeploySite(deploySite);
    } else {
      if(!await callDialog(AddDeploySiteDialog, {
        project: props.project,
      })) return;
      refetchDeploySites();
    }
  };

  const deployInfo = createMemo(() => {
    const deploySite = selectedDeploySite();
    if(!deploySite) return null;

    const packageMapFunc = (() => {
      switch(deploySite.provider) {
      case 'itch':
        return (pkg: PackageInfo) => {
          const channel = pkg.tags.toSorted(compareTags).map((tag) => tag.split(':').at(-1)).join('-') || 'default';
          return {
            id: pkg.id,
            title: pkg.title,
            size: pkg.size,
            channel,
            channelTitle: `${deployProvidersInfos.itch.siteTitle(deploySite.site)}:${channel}`,
          };
        };
      default:
        return null;
      }
    })();
    if(!packageMapFunc) return null;

    const packages = versionPackages();
    if(!packages) return null;
    return {
      deploySite,
      packages: packages.map(packageMapFunc),
    };
  });

  const errorInfo = createMemo(() => {
    const info = deployInfo();
    if(!info) return null;

    if(!info.packages.length) {
      return t('deploy_to_site_dialog.error.no_packages');
    }
    const channelsPackagesTitles = new Map<string, string[]>();
    for(const pkg of info.packages) {
      const channelPackagesTitles = channelsPackagesTitles.get(pkg.channel) ?? [];
      channelPackagesTitles.push(pkg.title);
      channelsPackagesTitles.set(pkg.channel, channelPackagesTitles);
    }
    for(const [channel, packagesTitles] of channelsPackagesTitles) {
      if(packagesTitles.length > 1) {
        return t('deploy_to_site_dialog.error.non_unique_channels')(packagesTitles.length, channel);
      }
    }

    return null;
  });

  const [isDeploying, setIsDeploying] = createSignal(false);
  const [deployJobsStates, setDeployJobsStates] = createSignal<Accessor<JobState>[] | undefined>();
  const isFinished = createMemo(() => {
    const states = deployJobsStates();
    if(states === undefined) return false;
    return states.every((state) => state().output !== undefined);
  });

  const onDeployOrClose = async (e: MouseEvent) => {
    if(isFinished()) {
      props.resolve(true);
      return;
    }
    const info = deployInfo();
    if(!info) return;
    await showErrorScope(async () => {
      setIsDeploying(true);
      setDeployJobsStates(await watchJobs(await api.postProjectsVersionsDeploy({
        project: props.project.id,
        version: props.version.id,
        requestBody: {
          site: info.deploySite.id,
          packages: info.packages.map((pkg) => ({
            package: pkg.id,
            channel: pkg.channel,
          })),
        },
      })));
    });
  };

  const DeployJobProgress: Component<{
    deployJobState: JobState;
  }> = (props) => <>{
    t(`deploy_to_site_dialog.job.${
      props.deployJobState.output !== undefined
        ? (props.deployJobState.output?.ok ? 'succeeded' : 'errored')
        : (props.deployJobState.progress ?? 'queued')
    }`)
  }</>;

  return (
    <props.dialog class="sheet ui big deploy_to_site">
      <h1>{t('deploy_to_site_dialog.header')}</h1>
      <div class="message">{t('deploy_to_site_dialog.message')(props.project.title, props.version.title)}</div>
      <label>
      <div class="label">{t('deploy_to_site_dialog.label_site')}</div>
        <Dropdown classList={{
          value: true,
        }} title={deploySiteTitle(selectedDeploySite()) ?? t('deploy_to_site_dialog.select_site')} items={[...(deploySites()?.map((deploySite) => ({
          key: deploySite,
          title: deploySiteTitle(deploySite),
        })) ?? []), {
          key: null,
          title: t('deploy_to_site_dialog.select_site.new'),
        }]} onSelect={onSelectDeploySite} disabled={isDeploying()} />
      </label>
      <Show when={selectedDeploySite()}>
        <div class="list packages">
          <For each={deployInfo()?.packages} fallback={
            <div>
              <ResourceFallback resource={versionPackages} />
            </div>
          }>{(pkg, packageIndex) =>
            <div>
              <Show when={deployJobsStates()} fallback={
                <div class="title">{pkg.title}</div>
              }>
                <div class="job"><DeployJobProgress deployJobState={deployJobsStates()![packageIndex()]()} /></div>
              </Show>
              <PrettyDataSize size={pkg.size} />
              <FaIcon solid right-long weak />
              <div class="channel"><DeployProviderIcon provider={selectedDeploySite()!.provider} />{pkg.channelTitle}</div>
            </div>
          }</For>
        </div>
        <div class="message weak">{t(`deploy_to_site_dialog.packages.message.${selectedDeploySite()!.provider}`)}</div>
      </Show>
      <Show when={errorInfo()}>
        <div class="message">
          <FaIcon solid triangle-exclamation error inline />
          <span>{errorInfo()}</span>
        </div>
      </Show>
      <div class="footer">
        <div class="buttons align_end">
          <Button
            disabled={selectedDeploySite() == null || errorInfo() != null || (isDeploying() && !isFinished())}
            onClick={onDeployOrClose}
          >{t(isFinished() ? 'deploy_to_site_dialog.close' : 'deploy_to_site_dialog.deploy')}</Button>
          <Button onClick={() => props.resolve(false)} autofocus disabled={isDeploying()}>{t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};
