import {
  Component,
  For,
  Match,
  Show,
  Switch,
  createResource,
  createSignal,
  useContext,
} from 'solid-js';
import {
  AuthContext,
  AuthProvider,
  CredentialProviderLabel,
  LocalizationContext,
  useRoutingNavigate,
} from '../components';

const AuthDialog: Component = () => {
  const { t } = useContext(LocalizationContext)!;
  const { providers, startAuth } = useContext(AuthContext)!;

  const [selectedProvider, setSelectedProvider] = createSignal<AuthProvider | null>(null);

  const onStartAuth = async (provider: AuthProvider) => {
    if(selectedProvider()) return;
    setSelectedProvider(provider);
    await startAuth(provider, 'register');
  };

  return <>
    <div class="sheet small ui auth">
      <h1>{ t('auth.dialog.header') }</h1>
      <Show when={selectedProvider() == null} fallback={
        <p>{ t('auth.dialog.redirecting_to')(t(`auth.provider.${selectedProvider()}`)) }</p>
      }>
        <p>{ t('auth.dialog.please_select') }</p>
        <div class="buttons">
          <For each={providers}>{
            (provider) =>
              <button class="button" onClick={[onStartAuth, provider]}><CredentialProviderLabel provider={provider} /></button>
          }</For>
        </div>
      </Show>
      <div class="footer"></div>
    </div>
  </>;
};

export const Oauth: Component<{
  route: string;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const { finishAuth } = useContext(AuthContext)!;
  const provider = props.route as AuthProvider;
  const navigate = useRoutingNavigate();

  const [authResult] = createResource(async () => {
    const { intent, nextUrl } = await finishAuth(provider);
    if(nextUrl) {
      navigate(nextUrl, {
        replace: true,
      });
    } else {
      switch(intent) {
      case 'login':
      case 'register':
        navigate('/', {
          replace: true,
        });
        break;
      case 'link':
        navigate('/me', {
          replace: true,
        });
        break;
      }
    }
  });

  return <>
    <Switch fallback={
      <ProgressSheet title={t('auth.dialog.progress_header')} />
    }>
      <Match when={authResult.error}>
        <div>{authResult.error}</div>
      </Match>
    </Switch>
  </>;
};

export const AuthWall: Component = () => {
  const { t } = useContext(LocalizationContext)!;
  const { status } = useContext(AuthContext)!;

  return (
    <Switch>
      <Match when={({
        not_authenticated: true,
      } as {
        [k: string]: boolean;
      })[status()]}>
        <AuthDialog />
      </Match>
      <Match when={status() == 'error'}>
        <div>{t('error')}</div>
      </Match>
      <Match when={true}>
        <ProgressSheet title={t('auth.dialog.progress_header')} />
      </Match>
    </Switch>
  );
};

const ProgressSheet: Component<{
  title: any;
  children?: any;
}> = (props) => {
  return (
    <div class="sheet ui small auth_progress">
      <h1>{ props.title }</h1>
      { props.children }
      <div class="footer"></div>
    </div>
  );
};
