import {
  Map as IMap,
  Set as ISet,
} from 'immutable';
import {
  Component,
  createMemo,
  createResource,
  For,
  JSXElement,
  Show,
  useContext,
} from 'solid-js';
import {
  allTags,
  AppPage,
  AuthContext,
  Button,
  CredentialProviderLabel,
  Dropdown,
  FaIcon,
  FormatDate,
  Link,
  LocalizationContext,
  MarkdownView,
  PrettyDataSize,
  ReportLink,
  ResourceFallback,
  Routes,
  Tags,
  useRoutingNavigate,
  WebPlayer,
} from '../../components';
import {
  StoreBranchInfo,
  StorePackageInfo,
  StoreProjectInfo,
} from '../../components/api-generated';
import config from '../../config';
import { showErrorScope } from '../../dialogs/basic';
import {
  linkStore,
  linkStoreProject,
  linkStoreProjectBranchPackagePlay,
} from './links';

export const RouteStore: Component = () => {
  const { tokenlessApi: api } = useContext(AuthContext)!;
  const { t } = useContext(LocalizationContext)!;

  return <>
    <AppPage
      title={t('route.store')}
      breadcrumb={<Link href={linkStore}>{t('route.store')}</Link>}
      uiClass="store"
    />
    <Routes routes={[
      {
        path: '',
        component: () => <>
          <h1>{t('route.store')}</h1>
          <div class="highlight warning"><p>{t('in_development')}</p></div>
          <div class="footer">
          </div>
        </>,
      },
      {
        component: (props) => {
          const [project] = createResource(async () => {
            try {
              return await api.getStoreProjects({
                project: props.route,
              });
            } catch(e) {
              return undefined;
            }
          });
          const branches = createMemo(() => IMap(project()?.branches?.map?.((branch) => [branch.id, branch])));
          return <>
            <AppPage
              title={project()?.title ?? '??'}
              breadcrumb={<Link href={linkStoreProject(project()?.id ?? '')}>{project()?.title ?? ''}</Link>}
              uiClass="store_project"
            />
            <Routes routes={[
              {
                path: '',
                component: () => <>
                  <Show when={project()} fallback={<ResourceFallback resource={project} page />}>
                    <h1>{project()!.title}<div class="hint">{t('store.project.hint')}</div></h1>
                    <div class="highlight warning">
                      <p>{t('store.project.content.warning')}</p>
                    </div>
                    <h2>{t('store.project.owner')}</h2>
                    <div class="list owner">
                      <div>
                        <div>
                          <div class="owner">{project()!.owner.title}</div>
                          <For each={project()!.owner.credentials}>{(credential) => <>
                            <Show when={credential.profileUrl} fallback={<div class="credential"><CredentialProviderLabel provider={credential.provider} /></div>}>
                              <a class="credential" href={credential.profileUrl} rel="ugc nofollow" target="_blank"><CredentialProviderLabel provider={credential.provider} /></a>
                            </Show>
                          </>}</For>
                        </div>
                      </div>
                    </div>
                    <h2>{t('store.project.description')}</h2>
                    <div class="description">
                      <MarkdownView text={() => project()!.description} ugc={true} level={2} linkInNewTab={true} />
                    </div>
                    <h2>{t('store.project.content')}</h2>
                    <For each={project()!.branches}>{(branch) => <>
                      <h3>{t('store.project.branch_version')(branch.title, branch.versionTitle)}</h3>
                      <p>{t('store.project.branch_version.published')(<FormatDate date={new Date(branch.branchVersionPublished)} />)}</p>
                      <div class="list packages">
                        <For each={branch.packages}>{(pkg) => <StorePackage project={project()!} branch={branch} pkg={pkg} />}</For>
                      </div>
                    </>}</For>
                    <div class="footer">
                      <div class="buttons align_end">
                        <ReportLink
                          title={<><FaIcon regular flag inline weak />{t('report_content')}</>}
                          subject={`Report store project ${project()?.id ?? ''}`}
                          body={
`Re: store project ${project()?.id ?? ''}
Link: ${window.location.href}
Title: ${project()?.title ?? ''}
`}
                        />
                      </div>
                    </div>
                  </Show>
                </>,
              },
              {
                component: (props) => {
                  const branch = createMemo(() => branches().get(props.route));
                  const packages = createMemo(() => IMap(branch()?.packages?.map?.((pkg) => [pkg.id, pkg])));
                  return <Show when={branch()}>
                    <AppPage
                      title={branch()!.title}
                      breadcrumb={branch()!.title}
                      uiClass="store_branch"
                    />
                    <Routes routes={[
                      {
                        component: (props) => {
                          const pkg = createMemo(() => packages().get(props.route));
                          return <>
                            <AppPage
                              title={pkg()!.title}
                              breadcrumb={pkg()!.title}
                              uiClass="store_package"
                            />
                            <Routes routes={[
                              {
                                path: 'play',
                                component: () => {
                                  const [spaceUrlResource] = createResource(async () => {
                                    return await api.getStoreProjectsBranchesPackagesSpace({
                                      project: project()!.id,
                                      branch: branch()!.id,
                                      _package: pkg()!.id,
                                    });
                                  });
                                  return <WebPlayer spaceUrlResource={spaceUrlResource} />;
                                },
                              },
                            ]} />
                          </>;
                        },
                      },
                    ]} />
                  </Show>;
                },
              },
            ]} />
          </>;
        },
      },
    ]} />
  </>;
};

const StorePackage: Component<{
  project: StoreProjectInfo;
  branch: StoreBranchInfo;
  pkg: StorePackageInfo;
}> = (props) => {
  const { tokenlessApi: api } = useContext(AuthContext)!;
  const { t } = useContext(LocalizationContext)!;
  const navigate = useRoutingNavigate();

  type TagInfo = {
    os?: string;
  };
  const tagsInfo = createMemo<TagInfo>(() => {
    const osTags: string[] = [];
    const info: TagInfo = {};
    for(const tag of props.pkg.tags) {
      const [tagType, tagValue] = tag.split(':');
      switch(tagType) {
      case 'os':
        osTags.push(tagValue);
        break;
      }
    }
    if(osTags.length == 1) {
      info.os = osTags[0];
    }
    return info;
  });

  type Action = {
    title: JSXElement;
    onAction: () => void;
  };

  const actions = createMemo<Action[]>(() => {
    const actions: Action[] = [];
    if(props.pkg.tags.indexOf('os:web') >= 0) {
      actions.push({
        title: <><FaIcon solid play weak inline />{t('packages.play')}</>,
        onAction: () => {
          navigate(linkStoreProjectBranchPackagePlay({
            projectId: props.project.id,
            branchId: props.branch.id,
            packageId: props.pkg.id,
          }));
        },
      });
    }
    actions.push({
      title: <><FaIcon solid file-zipper weak inline />{t('packages.download_zip')}</>,
      onAction: () => showErrorScope(async () => {
        const url = new URL(await api.getStoreProjectsBranchesPackagesDownload({
          project: props.project.id,
          branch: props.branch.id,
          _package: props.pkg.id,
        }), config.storageBaseUrl);
        url.searchParams.set('filter', 'zip');
        const link = document.createElement('a');
        link.href = url.toString();
        link.download = `${props.project.title} ${props.pkg.title}.zip`;
        link.click();
      }),
    });
    actions.push({
      title: <><FaIcon solid magnet weak inline />{t('packages.download_torrent')}</>,
      onAction: () => showErrorScope(async () => {
        const url = new URL(await api.getStoreProjectsBranchesPackagesTorrent({
          project: props.project.id,
          branch: props.branch.id,
          _package: props.pkg.id,
        }), config.storageBaseUrl);
        const link = document.createElement('a');
        link.href = url.toString();
        link.download = `${props.project.title} ${props.pkg.title}.torrent`;
        link.click();
      }),
    });
    return actions;
  });

  return (
    <div>
      <Show when={tagsInfo().os}><FaIcon fa-2xl {...allTags.os.options[tagsInfo().os!].icon} /></Show>
      <div class="title">{props.pkg.title}</div>
      <PrettyDataSize size={props.pkg.size} />
      <Show when={actions().length > 0}>
        <div class="buttons group">
          <Button onClick={actions()[0].onAction}>{actions()[0].title}</Button>
          <Show when={actions().length > 1}>
            <Dropdown title="" items={actions().slice(1).map(({ title, onAction }) => ({
              key: onAction,
              title,
            }))} onSelect={(onAction: () => void) => onAction()} alignEnd />
          </Show>
        </div>
      </Show>
      <Tags tags={ISet(props.pkg.tags)} showIcons={false} />
    </div>
  );
};
