import {
  Map as IMap,
  Set as ISet,
} from 'immutable';
import {
  Component,
  For,
  Show,
  createResource,
  createSignal,
  useContext,
} from 'solid-js';
import {
  AuthContext,
  Button,
  DialogParams,
  LocalizationContext,
  PrettyDataSize,
  Tags,
} from '../components';
import {
  PackageInfo,
  ProjectInfo,
} from '../components/api-generated';

export const AddExistingPackageDialog: Component<DialogParams<PackageInfo[]> & {
  project: ProjectInfo;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const { api } = useContext(AuthContext)!;

  const [packages] = createResource<PackageInfo[]>(async () => {
    return await api.getProjectsPackages({
      project: props.project.id,
    });
  });

  const [selectedPackages, setSelectedPackages] = createSignal(IMap<string, PackageInfo>());

  const onItemClick = (pkg: PackageInfo, e: MouseEvent) => {
    if(e.ctrlKey) setSelectedPackages((selected) =>
      selected.has(pkg.id)
        ? selected.delete(pkg.id)
        : selected.set(pkg.id, pkg)
    );
    else setSelectedPackages(IMap([[pkg.id, pkg]]));
  };

  return (
    <props.dialog class="sheet ui big add_existing_package">
      <h1>{t('packages.add_existing_dialog.header')}</h1>
      <Show when={packages()?.length} fallback={
        <div class="message">{t('packages.add_existing_dialog.no_packages')}</div>
      }>
        <div class="message">{t('packages.add_existing_dialog.message')}</div>
        <div class="list packages">
          <For each={packages()}>{(pkg) =>
            <div classList={{
              link: true,
              selected: selectedPackages().has(pkg.id),
            }} onClick={(e) => onItemClick(pkg, e)}>
              <div class="title">{pkg.title}</div>
              <Tags tags={ISet(pkg.tags)} />
              <PrettyDataSize size={pkg.size} />
            </div>
          }</For>
        </div>
      </Show>
      <div class="footer">
        <div class="buttons align_end">
          <Button disabled={selectedPackages().isEmpty()} onClick={(e:MouseEvent) => {
            e.preventDefault();
            props.resolve(selectedPackages().valueSeq().toArray());
          }}>{t('packages.add_existing_dialog.add')(selectedPackages().size)}</Button>
          <Button onClick={(e: MouseEvent) => {
            e.preventDefault();
            props.resolve();
          }}>{t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};
