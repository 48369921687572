import {
  List as IList,
  Map as IMap,
} from 'immutable';
import {
  Accessor,
  Setter,
  createMemo,
  createSignal,
} from "solid-js";

export type EntryId = number;
export abstract class Entry {
  id: EntryId;
  name: Accessor<string>;
  setName: Setter<string>;
  parent: ContainerEntry | null = null;
  abstract totalSize(): number;
  abstract clone(): this;
  extra: any;

  protected constructor(initialName: string) {
    const [name, setName] = createSignal(initialName);
    this.name = name;
    this.setName = setName;
  }

  removeFromParent() {
    if(this.parent) {
      this.parent.setEntries((entries) => entries.delete(this.name()));
      this.parent = null;
    }
  }
  moveTo(destEntry: ContainerEntry) {
    this.removeFromParent();
    destEntry.add(this);
  }
}

export abstract class ContainerEntry extends Entry {
  constructor(name: string) {
    super(name);
    const [entries, setEntries] = createSignal(IMap<string, Entry>());
    this.entries = entries;
    this.setEntries = setEntries;
    this.totalSize = createMemo(() => {
      let totalSize = 0;
      for(const [, entry] of this.entries()) {
        totalSize += entry.totalSize();
      }
      return totalSize;
    });
  }

  add(entry: Entry) {
    this.setEntries((entries) => entries.set(entry.name(), entry));
    entry.parent = this;
  }

  addInputFiles(inputFiles: Iterable<File>, onEntry?: (entry: Entry) => void) {
    for(const inputFile of inputFiles) {
      const path = IList<string>((inputFile.webkitRelativePath || inputFile.name).replaceAll('\\', '/').split('/'));
      // traverse path, creating directories on the way
      let directory: DirectoryEntry = this;
      for(let i = 0; i + 1 < path.size; ++i) {
        const nextDirectory = directory.entries().get(path.get(i)!);
        if(nextDirectory == null) {
          const newDirectory = new DirectoryEntry(path.get(i)!);
          onEntry?.(newDirectory);
          directory.add(newDirectory);
          directory = newDirectory;
        } else if(!(nextDirectory instanceof DirectoryEntry)) {
          throw {
            error: 'path_not_directory',
            path: path.slice(0, i + 1),
          };
        } else {
          directory = nextDirectory as DirectoryEntry;
        }
      }

      // create file
      if(directory.entries().has(path.last())) {
        throw {
          error: 'file_exists',
          path: path,
        };
      }
      const entry = new FileEntry(path.last(), {
        size: inputFile.size,
        blob: inputFile,
      });
      onEntry?.(entry);
      directory.add(entry);
    };
  }

  async addInputFileSystemEntry(fileSystemEntry: FileSystemEntry, onEntry?: (entry: Entry) => void) {
    const name = fileSystemEntry.name;
    if(fileSystemEntry.isDirectory) {
      const entry = new DirectoryEntry(name);
      onEntry?.(entry);
      this.add(entry);

      const directoryReader = (fileSystemEntry as FileSystemDirectoryEntry).createReader();
      for(;;) {
        const subEntries: FileSystemEntry[] = await new Promise((resolve, reject) => directoryReader.readEntries(resolve, reject));
        if(subEntries.length <= 0) break;
        for(const subEntry of subEntries)
          await entry.addInputFileSystemEntry(subEntry, onEntry);
      }
    } else if(fileSystemEntry.isFile) {
      const file: File = await new Promise((resolve, reject) => (fileSystemEntry as FileSystemFileEntry).file(resolve, reject));
      const entry = new FileEntry(name, {
        size: file.size,
        blob: file,
      });
      onEntry?.(entry);
      this.add(entry);
    }
  }
  async addInputDataTransferItems(items: DataTransferItemList, onEntry?: (entry: Entry) => void) {
    for(const item of items) {
      const entry = item.getAsEntry?.() ?? item.webkitGetAsEntry?.();
      if(entry) {
        await this.addInputFileSystemEntry(entry, onEntry);
      }
    }
  }

  entries: Accessor<IMap<string, Entry>>;
  setEntries: Setter<IMap<string, Entry>>;
  totalSize: Accessor<number>;
}

export class DirectoryEntry extends ContainerEntry {
  constructor(name: string) {
    super(name);
  }

  override clone(): this {
    const clonedEntry = new DirectoryEntry(this.name());
    clonedEntry.setEntries(this.entries().map((subentry) => {
      const clonedSubentry = subentry.clone();
      clonedSubentry.parent = clonedEntry;
      return clonedSubentry;
    }));
    return clonedEntry as this; // appease type checker; OK because this class is final
  }
}

export class FileEntry extends Entry {
  constructor(name: string, init: {
      size: number;
      blob?: Blob;
      mode?: FileEntryMode;
      chunks?: FileEntryChunk[];
      offset?: number;
    }) {
    super(name);
    this.size = init.size;
    this.blob = init.blob;
    const [mode, setMode] = createSignal(init.mode ?? (0 as FileEntryMode));
    this.mode = mode;
    this.setMode = setMode;
    this.chunks = init.chunks ?? undefined;
    this.offset = init.offset;
  }

  totalSize(): number {
    return this.size;
  }

  override clone(): this {
    return new FileEntry(this.name(), {
      size: this.size,
      blob: this.blob,
      mode: this.mode(),
      chunks: this.chunks,
      offset: this.offset,
    }) as this; // appease type checker; OK because this class is final
  }

  size: number;
  blob?: Blob;
  mode: Accessor<FileEntryMode>;
  setMode: Setter<FileEntryMode>;
  chunks?: FileEntryChunk[];
  offset?: number;
}

export type FileEntryChunk = {
  hash: ArrayBuffer;
  size: number;
  compressedSize: number;
}

export enum FileEntryMode {
  Executable = 1,
  Fuck = 2,
}

export class SymlinkEntry extends Entry {
  constructor(name: string, link: string) {
    super(name);
    this.link = link;
  }

  totalSize(): number {
    return 0;
  }

  override clone(): this {
    return new SymlinkEntry(this.name(), this.link) as this; // appease type checker; OK because this class is final
  }

  link: string;
}
