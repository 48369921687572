import {
  Component,
  Show,
  createMemo,
  createSignal,
  onMount,
  useContext,
} from "solid-js";
import {
  AuthContext,
  Button,
  DeployProvider,
  DeployProviderLabel,
  DialogParams,
  Dropdown,
  LocalizationContext,
  deployProviders,
} from "../components";
import {
  showErrorScope,
} from "./basic";
import { DateTime } from "luxon";

export const AddDeployCredentialDialog: Component<DialogParams<string | null>> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const { api } = useContext(AuthContext)!;

  const [provider, setProvider] = createSignal<DeployProvider>('itch');
  const providerInfo = createMemo<{
    key?: boolean;
    isKeyValid?: (key: string) => boolean;
    hint?: boolean;
  }>(() => ({
    steam: {
      hint: true,
    },
    itch: {
      key: true,
      isKeyValid: (key: string) => /^[a-zA-Z0-9]{40}$/.test(key),
      hint: true,
    },
  }[provider()]));

  const [key, setKey] = createSignal('');

  let refTitle: HTMLInputElement | undefined;
  onMount(() => {
    refTitle!.value = t('me.deploy_credential.add_dialog.default_title')(DateTime.fromJSDate(new Date()).toLocaleString({
      dateStyle: 'long',
    }, {
      locale: t('locale'),
    }));
  });

  const onAdd = async (e: MouseEvent) => {
    e.preventDefault();
    await showErrorScope(async () => {
      const credentialId = await api.postUsersMeDeployCredentials({
        requestBody: {
          title: refTitle!.value,
          provider: provider(),
          key: key(),
        },
      });
      props.resolve(credentialId);
    });
  };

  return (
    <props.dialog class="sheet small ui add_deploy_credential">
      <h1>{t('me.deploy_credential.add_dialog.header')}</h1>
      <label>
        <div class="label">{t('me.deploy_credential.add_dialog.label_site')}</div>
        <Dropdown title={<DeployProviderLabel provider={provider()} />} classList={{
          value: true,
        }} items={deployProviders.map((provider) => ({
          key: provider,
          title: <DeployProviderLabel provider={provider} />,
        }))} onSelect={setProvider} />
      </label>
      <label>
        <div class="label">{t('me.deploy_credential.add_dialog.label_title')}</div>
        <div class="value"><input type="text" ref={refTitle}/></div>
      </label>
      <Show when={providerInfo().key}>
        <label>
          <div class="label">{t('me.deploy_credential.add_dialog.label_key')}</div>
          <div class="value"><input type="password" onInput={(e) => {
            e.preventDefault();
            setKey(e.target.value);
          }} /></div>
        </label>
      </Show>
      <Show when={providerInfo().hint}>
        <div class="hint weak">{t(`me.deploy_credential.add_dialog.hint.${provider()}`)}</div>
      </Show>
      <div class="footer">
        <div class="buttons align_end">
          <Button onClick={onAdd} disabled={!providerInfo().isKeyValid?.(key())}>{t('me.deploy_credential.add_dialog.ok')}</Button>
          <Button autofocus onClick={() => props.resolve(null)}>{t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};
