import {
  Component,
  For,
  createMemo,
  createSignal
} from 'solid-js';
import { Dynamic } from 'solid-js/web';

type TabParams = {
  title: any;
  component: Component;
  path: string;
  link?: string;
};

export const Tab = (props: TabParams) => props;

export const Tabs: Component<{
  tabs: {
    title: any;
    component: Component;
    }[];
}> = (props) => {
  const [activeTabIndex, setActiveTabIndex] = createSignal(0);
  const currentTab = createMemo(() => props.tabs[activeTabIndex()]);

  return (
    <div class="tabs">
      <div class="header">
        <For each={props.tabs}>{(tab, index) =>
          <div classList={{
            tab: true,
            active: index() == activeTabIndex(),
          }} onClick={() => setActiveTabIndex(index())}>
            <div>{ tab.title }</div>
          </div>
        }</For>
      </div>
      <Dynamic component={currentTab().component} />
    </div>
  );
};
