import {
  ReferenceElement as FloatingUIReferenceElement,
} from '@floating-ui/dom';
import {
  List as IList,
  Map as IMap,
} from 'immutable';
import {
  Accessor,
  Component,
  For,
  Match,
  Setter,
  Show,
  Switch,
  createContext,
  createMemo,
  createResource,
  createSignal,
  onCleanup,
  onMount,
  useContext,
} from 'solid-js';
import { ArchiveChunksIndex, gear17Chunking } from './archive';
import {
  ContextMenu,
  FaIcon,
} from './basic';
import {
  ContainerEntry,
  DirectoryEntry,
  Entry,
  EntryId,
  FileEntry,
  FileEntryChunk,
  FileEntryMode,
  SymlinkEntry,
} from './entries';
import {
  LocalizationContext,
} from './localization';
import { buffer2hex } from './utils';

const compareByName = (a: Entry, b: Entry) => {
  const nameA = a.name();
  const nameB = b.name();
  if(nameA < nameB) return -1;
  if(nameA > nameB) return 1;
  return 0;
};

class EntryRegistry {
  allEntries = new Map<EntryId, Entry>();
  nextEntryId = 0;

  get(entryId: EntryId) {
    return this.allEntries.get(entryId);
  }

  register(entry: Entry) {
    entry.id = this.nextEntryId++;
    this.allEntries.set(entry.id, entry);
  }

  unregister(entry: Entry) {
    this.allEntries.delete(entry.id);
  }
}

const EntryContext = createContext<{
  entryRegistry: EntryRegistry;
  editable: boolean;
  processAddedEntry?: (entry: Entry) => void;
  baseChunksIndex?: ArchiveChunksIndex;
  onContextMenu: (entry: Entry, e: MouseEvent) => void;
}>();
export const EntryContextProvider: Component<{
  editable?: boolean;
  processAddedEntry?: (entry: Entry) => void;
  baseChunksIndex?: ArchiveChunksIndex;
  onContextMenu?: (entry: Entry, e: MouseEvent) => void;
  children: any;
}> = (props) => {
  const parentContext = useContext(EntryContext);
  const entryRegistry = parentContext?.entryRegistry ?? new EntryRegistry();
  return (
    <EntryContext.Provider value={{
      entryRegistry,
      editable: props.editable ?? false,
      processAddedEntry: props.processAddedEntry,
      baseChunksIndex: props.baseChunksIndex,
      onContextMenu: props.onContextMenu ?? (() => {}),
    }}>{props.children}</EntryContext.Provider>
  );
};


type PathSelection = 'selected' | SelectedPaths;
type SelectedPaths = IMap<string, PathSelection>;

const emptySelectedPaths: SelectedPaths = IMap();

const modifyPathSelection = (path: IList<string>, modify: (pathSelection: PathSelection) => PathSelection, selectedPaths: SelectedPaths): SelectedPaths => {
  // should not be called with empty path
  if(!path.size) {
    return selectedPaths;
  }

  const name = path.first()!;
  const pathSelection = selectedPaths.get(name);

  const newPathSelection: PathSelection = path.size == 1
    ? modify(pathSelection ?? emptySelectedPaths)
    : modifyPathSelection(path.shift(), modify,
        (pathSelection === undefined || pathSelection == 'selected')
        ? emptySelectedPaths
        : pathSelection
      )
  ;
  return (newPathSelection != 'selected' && newPathSelection.isEmpty()) ? selectedPaths.delete(name) : selectedPaths.set(name, newPathSelection);
};

const singleSelectedPath = (path: IList<string>): SelectedPaths => modifyPathSelection(path, (pathSelection) => 'selected', emptySelectedPaths);

export type EntryUploadExtra = {
  patchSize: (baseEntry?: Entry) => Accessor<number>;
  ready: Accessor<boolean>;
};

const EntryRow: Component<{
  entry: Entry;
  baseEntry?: Entry;
  // full path to the entry
  path: IList<string>;
  pathSelection: PathSelection;
  setSelectedPaths: Setter<SelectedPaths>;
  level: number;
}> = (props) => {
  const { entryRegistry, editable, processAddedEntry, onContextMenu } = useContext(EntryContext)!;
  onMount(() => {
    entryRegistry.register(props.entry);
  });
  onCleanup(() => {
    entryRegistry.unregister(props.entry);
  });

  const { t } = useContext(LocalizationContext)!;

  const [open, setOpen] = createSignal(false);

  const onMouseDown = (e: MouseEvent) => {
    props.setSelectedPaths((selectedPaths) =>
      e.ctrlKey
        ? modifyPathSelection(props.path, (pathSelection) => pathSelection == 'selected' ? emptySelectedPaths : 'selected', selectedPaths)
        : singleSelectedPath(props.path)
    );
  };
  const onClick = (e: MouseEvent) => {
    if(props.entry instanceof ContainerEntry) {
      setOpen((open) => !open);
    }
  };

  let refName: HTMLDivElement | undefined;

  const isParentEntry = (potentialParentEntry: Entry) => {
    for(let entry: Entry | null = props.entry; entry; entry = entry.parent) {
      if(entry == potentialParentEntry) return true;
    }
    return false;
  };

  // row as draggable
  const onDragStart = (e: DragEvent) => {
    if(!editable) {
      e.preventDefault();
      return;
    }
    e.dataTransfer!.setData('app/entry', JSON.stringify(props.entry.id));
    e.dataTransfer!.effectAllowed = 'move';
    e.dataTransfer!.setDragImage(refName!, 0, 0);
  };
  // row as drop area
  const [dropping, setDropping] = createSignal(0);
  const onDragEnter = (e: DragEvent) => {
    e.preventDefault();
    if(!editable) return;
    setDropping((dropping) => dropping + 1);
  };
  const onDragLeave = (e: DragEvent) => {
    if(!editable) return;
    setDropping((dropping) => dropping - 1);
  };
  const onDragOver = (e: DragEvent) => {
    e.preventDefault();
    if(!editable) return;

    e.dataTransfer!.dropEffect = 'none';

    if(!(props.entry instanceof ContainerEntry)) return;

    if(e.dataTransfer!.types.includes('app/entry')) {
      e.dataTransfer!.dropEffect = 'move';
    } else if(e.dataTransfer!.types.includes('Files')) {
      e.dataTransfer!.dropEffect = 'copy';
    }
  };
  const onDrop = async (e: DragEvent) => {
    // prevent default drop action like navigating
    e.preventDefault();
    if(!editable) return;

    // reset counter, browsers skip dragleave on drop
    setDropping(0);

    if(!(props.entry instanceof ContainerEntry)) return;

    // drop of entries
    if(e.dataTransfer!.types.includes('app/entry')) {
      // get entry
      const entry = entryRegistry.get(JSON.parse(e.dataTransfer!.getData('app/entry')));
      if(!entry) return;

      // check for loop
      if(isParentEntry(entry)) return;

      // move entry
      entry.moveTo(props.entry);

      // ensure parent is open
      setOpen(true);
      // select dragged entity
      props.setSelectedPaths(singleSelectedPath(props.path.push(entry.name())));
    }
    // drop of files from desktop
    else if(e.dataTransfer!.types.includes('Files')) {
      await props.entry.addInputDataTransferItems(e.dataTransfer!.items, processAddedEntry);
      // ensure parent is open and selected
      setOpen(true);
      props.setSelectedPaths(singleSelectedPath(props.path));
    }
  };

  return (
    <>
      <div
        classList={{
          entry: true,
          selected: props.pathSelection == 'selected',
          dropping: props.entry instanceof ContainerEntry && dropping() > 0,
        }}
        onMouseDown={onMouseDown}
        onClick={onClick}
        onContextMenu={[onContextMenu, props.entry]}
        draggable="true"
        onDragStart={onDragStart}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        aria-role="treeitem"
        aria-level={props.level + 1}
        aria-selected={props.pathSelection == 'selected' || undefined}
        aria-expanded={props.entry instanceof ContainerEntry ? open() : undefined}
      >
        <code ref={refName} class="name" style={{
          '--tree-level': props.level,
        }}>
          <Switch>
            <Match when={props.entry instanceof DirectoryEntry}>
              <FaIcon {...{
                [dropping() > 0 ? 'folder-plus' : open() ? 'folder-open' : 'folder']: true,
              }} solid />
            </Match>
            <Match when={props.entry instanceof FileEntry}>
              <FaIcon {...{
                [((props.entry as FileEntry).mode() & FileEntryMode.Executable) ? 'file-excel' : 'file']: true,
              }} regular />
            </Match>
            <Match when={props.entry instanceof SymlinkEntry}>
              <FaIcon link solid />
            </Match>
          </Switch>
          {props.entry.name()}
        </code>
        <PrettyDataSize size={props.entry.totalSize()} />
      </div>
      <Show when={props.entry instanceof ContainerEntry && open()}>
        <EntriesRows
          entries={(props.entry as ContainerEntry).entries}
          baseEntries={(props.baseEntry as ContainerEntry)?.entries()}
          level={props.level + 1}
          path={props.path}
          selectedPaths={props.pathSelection == 'selected' ? emptySelectedPaths : props.pathSelection}
          setSelectedPaths={props.setSelectedPaths}
        />
      </Show>
    </>
  );
};

const EntriesRows: Component<{
  entries: Accessor<IMap<string, Entry>>;
  baseEntries?: IMap<string, Entry>;
  path: IList<string>;
  // selected paths relative to the entry
  selectedPaths: SelectedPaths;
  // set absolute selected paths
  setSelectedPaths: Setter<SelectedPaths>;
  level: number;
}> = (props) =>
  <For each={props.entries().valueSeq().toArray().sort(compareByName)}>{(entry) =>
    <EntryRow
      entry={entry}
      baseEntry={props.baseEntries?.get(entry.name())}
      level={props.level}
      path={props.path.push(entry.name())}
      pathSelection={props.selectedPaths.get(entry.name()) ?? emptySelectedPaths}
      setSelectedPaths={props.setSelectedPaths}
    />
  }</For>
;

export const UploadBox: Component<{
  root: DirectoryEntry;
  baseRoot?: DirectoryEntry;
  baseChunksIndex?: ArchiveChunksIndex;
  editable?: boolean;
  classList?: any;
}> = (props) => {
  const { entryRegistry } = useContext(EntryContext)!;
  const { t } = useContext(LocalizationContext)!;

  const [selectedPaths, setSelectedPaths] = createSignal(emptySelectedPaths);

  const processAddedEntry = (entry: Entry) => {
    if(entry instanceof DirectoryEntry) {
      entry.extra = {
        patchSize: (baseEntry?: Entry) => createMemo(() => {
          let size = 0;
          const baseEntryIsContainer = baseEntry instanceof ContainerEntry;
          for(const [subentryName, subentry] of entry.entries()) {
            size += (subentry.extra as EntryUploadExtra).patchSize(
              baseEntryIsContainer ? baseEntry.entries().get(subentryName) : undefined
            )();
          }
          return size;
        }),
        ready: createMemo(() => {
          for(const [_subentryName, subentry] of entry.entries()) {
            if(!(subentry.extra as EntryUploadExtra)?.ready()) {
              return false;
            }
          }
          return true;
        }),
      } satisfies EntryUploadExtra;
    }
    else if(entry instanceof FileEntry) {
      if(entry.chunks || entry.blob) {
        const [ready, setReady] = createSignal(false);
        const [chunksResource] = createResource(async () => {
          if(!entry.chunks) {
            const chunks: FileEntryChunk[] = [];
            for await(const chunk of gear17Chunking(entry.blob!)) {
              chunks.push({
                ...chunk,
                compressedSize: 0,
              });
            }
            entry.chunks = chunks;
          }
          setReady(true);
          return entry.chunks;
        });
        entry.extra = {
          patchSize: () => createMemo(() => {
            const chunks = chunksResource();
            if(!chunks) return entry.size;
            const baseChunksIndex = props.baseChunksIndex;
            if(!baseChunksIndex) return entry.size;
            let size = 0;
            for(const chunk of chunks) {
              if(!baseChunksIndex.has(buffer2hex(chunk.hash))) {
                size += chunk.compressedSize || chunk.size;
              }
            }
            return size;
          }),
          ready,
        } satisfies EntryUploadExtra;
      } else {
        entry.extra = {
          patchSize: () => () => entry.size,
          ready: () => true,
        } satisfies EntryUploadExtra;
      }
    }
    else {
      entry.extra = {
        patchSize: () => () => 0,
        ready: () => true,
      } satisfies EntryUploadExtra;
    }
  };
  // recursively process initial entries
  (() => {
    const process = (entry: Entry) => {
      if(entry instanceof ContainerEntry) {
        for(const [_subentryName, subentry] of entry.entries()) {
          process(subentry);
        }
      }
      processAddedEntry(entry);
    };
    process(props.root);
  })();

  const onInputFiles = async (e: Event) => {
    e.preventDefault();

    props.root.addInputFiles((e.target as HTMLInputElement).files!, processAddedEntry);
  };

  const [contextMenuEntry, setContextMenuEntry] = createSignal<Entry | null>();
  const [contextEntryRef, setContextEntryRef] = createSignal<FloatingUIReferenceElement>();
  const [contextMenuVisible, setContextMenuVisible] = createSignal(false);
  const contextMenuItems = createMemo(() => {
    const items: {
      key: string;
      title: string;
      danger?: boolean;
    }[] = [];
    if(props.editable) {
      const entry = contextMenuEntry();
      if(entry instanceof DirectoryEntry) {
        items.push({
          key: 'explode_folder',
          title: t('upload_box.entry.explode_folder'),
        });
      }
      if(entry instanceof FileEntry) {
        const executable = entry.mode() & FileEntryMode.Executable;
        items.push({
          key: 'toggle_executable',
          title: t(`upload_box.entry.${executable ? 'unmark' : 'mark'}_executable`),
        });
      }
      items.push({
        key: 'remove',
        title: t('upload_box.entry.remove'),
      });
    }
    return items;
  });
  const onContextMenu = (entry: Entry, e: MouseEvent) => {
    if(!contextMenuItems().length) return;

    e.preventDefault();

    setContextMenuEntry(entry);
    setContextEntryRef({
      getBoundingClientRect: () => ({
        x: e.clientX,
        y: e.clientY,
        width: 0,
        height: 0,
        left: e.clientX,
        top: e.clientY,
        right: e.clientX,
        bottom: e.clientY,
      }),
      contextElement: e.currentTarget as Element,
    });
    setContextMenuVisible(true);
  };
  const onContextMenuSelect = (key: string) => {
    const entry = contextMenuEntry();
    if(!entry) return;
    switch(key) {
    case 'explode_folder':
      if(entry instanceof DirectoryEntry) {
        const parentEntry = entry.parent;
        if(parentEntry) {
          entry.removeFromParent();
          for(const [_subentryName, subentry] of entry.entries()) {
            subentry.moveTo(parentEntry);
          }
        }
      }
      break;
    case 'toggle_executable':
      if(entry instanceof FileEntry) {
        entry.setMode((mode) => mode ^ FileEntryMode.Executable);
      }
      break;
    case 'remove':
      entry.removeFromParent();
      break;
    }
  };

  const [boxDropping, setBoxDropping] = createSignal(0);
  const [areaDropping, setAreaDropping] = createSignal(0);

  const isDropAreaBig = createMemo(() => props.root.entries().isEmpty());

  return (
    <div classList={{
      'upload-box': true,
      ...props.classList ?? {},
    }}
      onDragEnter={(e: DragEvent) => {
        if(!props.editable) return;
        setBoxDropping((dropping) => dropping + 1);
      }}
      onDragLeave={(e: DragEvent) => {
        if(!props.editable) return;
        setBoxDropping((dropping) => dropping - 1);
      }}
      onDragOver={(e: DragEvent) => {
        if(!props.editable) return;
        if(e.target == e.currentTarget) {
          e.dataTransfer!.dropEffect = 'none';
        }
        e.preventDefault();
      }}
      onDrop={(e: DragEvent) => {
        if(!props.editable) return;
        setBoxDropping(0);
      }}
    >
      <Show when={props.editable}>
        <div class="buttons">
          <FileUploadButton onChange={onInputFiles}>{t('upload_box.choose_files')}</FileUploadButton>
          <DirectoryUploadButton onChange={onInputFiles}>{t('upload_box.choose_directories')}</DirectoryUploadButton>
        </div>
        <div classList={{
          'drop-area': true,
          big: isDropAreaBig(),
          dropping: areaDropping() > 0,
        }}
          onDragEnter={(e: DragEvent) => {
            setAreaDropping((dropping) => dropping + 1);
          }}
          onDragLeave={(e: DragEvent) => {
            setAreaDropping((dropping) => dropping - 1);
          }}
          onDragOver={(e: DragEvent) => {
            e.preventDefault();
            e.dataTransfer!.dropEffect = 'none';
            if(e.dataTransfer!.types.includes('app/entry')) {
              e.dataTransfer!.dropEffect = 'move';
            } else if(e.dataTransfer!.types.includes('Files')) {
              e.dataTransfer!.dropEffect = 'copy';
            }
          }}
          onDrop={async (e: DragEvent) => {
            e.preventDefault();
            setAreaDropping(0);
            // drop of entries
            if(e.dataTransfer!.types.includes('app/entry')) {
              // get entry
              const entry = entryRegistry.get(JSON.parse(e.dataTransfer!.getData('app/entry')));
              if(!entry) return;
              // move entry
              entry.moveTo(props.root);
              // select dragged entity
              setSelectedPaths(singleSelectedPath(IList.of(entry.name())));
            }
            // drop of files from desktop
            else if(e.dataTransfer!.types.includes('Files')) {
              await props.root.addInputDataTransferItems(e.dataTransfer!.items, processAddedEntry);
            }
          }}
        >
          <FaIcon solid box-open fa-2xl />
          <div>{boxDropping() ? t('upload_box.drop_area.box_dropping_message') : t('upload_box.drop_area.message')}</div>
        </div>
      </Show>
      <EntryContextProvider
        editable={props.editable}
        processAddedEntry={processAddedEntry}
        baseChunksIndex={props.baseChunksIndex}
        onContextMenu={onContextMenu}
      >
        <Show when={!props.root.entries().isEmpty()}>
          <div
            class="entries"
            aria-role="tree"
            aria-multiselectable={props.editable}
            aria-readonly={!props.editable}
          >
            <EntriesRows
              entries={props.root.entries}
              baseEntries={props.baseRoot?.entries()}
              level={0}
              path={IList()}
              selectedPaths={selectedPaths()}
              setSelectedPaths={setSelectedPaths}
            />
          </div>
          <ContextMenu items={contextMenuItems()} baseRef={contextEntryRef} menuVisible={contextMenuVisible} setMenuVisible={setContextMenuVisible} onSelect={onContextMenuSelect} />
        </Show>
      </EntryContextProvider>
    </div>
  );
};

export const FileUploadButton: Component<{
  onChange: any;
  children: any;
}> = (props) => {
  let inputRef;

  return (
    <label class="button file-upload">
      { props.children }
      <input type="file" multiple onChange={props.onChange} ref={inputRef} />
    </label>
  );
};

export const DirectoryUploadButton: Component<{
  onChange: any;
  children: any;
}> = (props) => {
  let inputRef;

  return (
    <label class="button directory-upload">
      { props.children }
      <input type="file" directory="" webkitdirectory="" multiple onChange={props.onChange} ref={inputRef} />
    </label>
  );
};

export const PrettyDataSize: Component<{
  size: number;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const countSuffix = createMemo(() => prettyDataSizeCountSuffix(props.size));
  return (
    <>
      <span class="size_count">{countSuffix()[0]}</span>
      <span class="size_suffix">{t(`datasize.${countSuffix()[1]}`)}</span>
    </>
  );
};

const prettyDataSizeCountSuffix = (size: number): [count: string, suffix: string] => {
  if(size < 1000) return [size.toFixed(0), 'B'];
  size /= 1024;
  if(size < 10) return [size.toFixed(1), 'KiB'];
  if(size < 1000) return [size.toFixed(0), 'KiB'];
  size /= 1024;
  if(size < 10) return [size.toFixed(1), 'MiB'];
  if(size < 1000) return [size.toFixed(0), 'MiB'];
  size /= 1024;
  if(size < 10) return [size.toFixed(1), 'GiB'];
  if(size < 1000) return [size.toFixed(0), 'GiB'];
  size /= 1024;
  if(size < 10) return [size.toFixed(1), 'TiB'];
  return [size.toFixed(0), 'TiB'];
};
