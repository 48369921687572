import {
  Component,
  Resource,
  Show,
  useContext,
} from 'solid-js';
import { Button, FaIcon } from './basic';
import { LocalizationContext } from './localization';
import { ResourceFallback } from './pieces';
import { AppPage } from './routing';

export const WebPlayer: Component<{
  spaceUrlResource: Resource<string>;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  let refFrame: HTMLIFrameElement;

  const onReset = () => {
    refFrame.src = props.spaceUrlResource()!;
  };

  const onFullScreen = () => {
    refFrame.requestFullscreen({
      navigationUI: 'hide',
    });
  };

  return <>
    <AppPage
      title={t('packages.play')}
      breadcrumb={t('packages.play')}
      uiClass="package_play"
    />
    <Show when={props.spaceUrlResource()} fallback={
      <ResourceFallback resource={props.spaceUrlResource} page />
    }>
      <iframe
        ref={refFrame!}
        src={props.spaceUrlResource()}
        allow='accelerometer; ambient-light-sensor; autoplay; fullscreen; gamepad; geolocation; gyroscope; hid; magnetometer; midi; screen-wake-lock'
      />
      <div class="footer">
        <div class="buttons">
          <Button onClick={onReset}><FaIcon power-off solid inline weak />{t('packages.play.reset')}</Button>
          <div class="filler" />
          <Button onClick={onFullScreen}><FaIcon display solid inline weak />{t('packages.play.fullscreen')}</Button>
        </div>
      </div>
    </Show>
  </>;
};
