import {
  Accessor,
  Signal,
  createSignal,
} from 'solid-js';
import config from '../config';

export type JobState = {
  output?: any;
  progress?: any;
};

export const watchJobs = (jobsTokens: string[]): Promise<Accessor<JobState>[]> => new Promise((resolve, reject) => {
  const jobs = jobsTokens.map((_jobToken) => createSignal<JobState>({}));

  let finishedCount = 0;

  const eventSource = new EventSource(`${config.apiUrl}/jobs?${new URLSearchParams(jobsTokens.map((jobToken) => ['tokens', jobToken]))}`);
  eventSource.addEventListener('open', (e) => {
    resolve(jobs.map((job) => job[0]));
  });
  eventSource.addEventListener('error', (e) => {
    reject(e);
  });
  eventSource.addEventListener('job_updated', (e) => {
    const data = JSON.parse(e.data);
    switch(data.type) {
    case 'output':
      jobs[data.job][1]((job) => ({
        ...job,
        output: data.output,
      }));
      break;
    case 'progress':
      jobs[data.job][1]((job) => ({
        ...job,
        progress: data.progress,
      }));
      break;
    }
    if(data.type == 'output' && ++finishedCount == jobs.length) {
      eventSource.close();
    }
  });
});

export class JobsWatcher {
  add(jobToken: string): Accessor<JobState> {
    const signal = createSignal<JobState>({});
    this.jobs.push({
      signal,
      token: jobToken,
      finished: false,
    });
    this.update();
    return signal[0];
  }

  update() {
    if(this.eventSource) this.eventSource.close();

    this.eventSource = new EventSource(`${config.apiUrl}/jobs?${new URLSearchParams(this.jobs.map((job) => ['tokens', job.token]))}`);

    const jobs = this.jobs; // so this version of jobs is captured in closure
    this.eventSource.addEventListener('job_updated', (e) => {
      const data = JSON.parse(e.data);
      switch(data.type) {
      case 'output':
        jobs[data.job].signal[1]((job) => ({
          ...job,
          output: data.output,
        }));
        break;
      case 'progress':
        jobs[data.job].signal[1]((job) => ({
          ...job,
          progress: data.progress,
        }));
        break;
      }
      if(data.type == 'output' && !jobs[data.job].finished) {
        jobs[data.job].finished = true;
        if(++this.finishedCount == jobs.length && this.eventSource) {
          this.eventSource.close();
          this.eventSource = undefined;
        }
      }
    });
  }

  eventSource?: EventSource;
  jobs: Job[] = [];
  finishedCount = 0;
}

type Job = {
  signal: Signal<JobState>;
  token: string;
  finished: boolean;
};
