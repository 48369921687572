import {
  Component,
  For,
  Show,
  createResource,
  createSignal,
  useContext,
} from "solid-js";
import {
  AuthContext,
  Button,
  DialogParams,
  LocalizationContext,
  ResourceFallback,
} from "../components";
import {
  BranchInfo,
  ProjectInfo,
  VersionInfo,
} from "../components/api-generated";

export const PublishVersionDialog: Component<DialogParams<string | null> & {
  project: ProjectInfo;
  version: VersionInfo;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const { api } = useContext(AuthContext)!;

  const [branches, { refetch: refetchBranches }] = createResource<BranchInfo[]>(async () => {
    return await api.getProjectsBranches({
      project: props.project.id,
    });
  });

  const [selectedBranch, setSelectedBranch] = createSignal<string | null>(null);

  const onItemClick = (branch: BranchInfo, e: MouseEvent) => {
    setSelectedBranch(branch.id);
  };

  return (
    <props.dialog class="sheet ui medium select_branch">
      <h1>{t('versions.publish_dialog.header')}</h1>
      <div class="message">{t('versions.publish_dialog.message')(props.version.title)}</div>
      <div class="list branches">
        <For each={branches()} fallback={
          <div>
            <ResourceFallback resource={branches} empty={<div class="empty">{t('versions.publish_dialog.empty')}</div>} />
          </div>
        }>{(branch) =>
          <div classList={{
            link: branch.currentVersion?.version.id !== props.version.id,
            current: branch.currentVersion?.version.id === props.version.id,
            selected: selectedBranch() === branch.id,
          }} onClick={branch.currentVersion?.version.id !== props.version.id ? [onItemClick, branch] : undefined}>
            <div class="title">{branch.title}</div>
            <Show when={branch.currentVersion}>
              <div class="current_version">{branch.currentVersion?.version?.title}</div>
            </Show>
          </div>
        }</For>
      </div>
      <div class="footer">
        <div class="buttons align_end">
          <Button disabled={selectedBranch() == null} onClick={(e:MouseEvent) => {
            e.preventDefault();
            props.resolve(selectedBranch());
          }}>{t('versions.publish_dialog.ok')}</Button>
          <Button onClick={(e: MouseEvent) => {
            e.preventDefault();
            props.resolve();
          }}>{t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};
