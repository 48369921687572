import {
  Accessor,
  Component,
  createContext,
  createEffect,
  createSignal,
  Setter,
  Show,
  useContext,
} from 'solid-js';
import {
  Dropdown,
  FaIcon,
} from './basic';
import {
  LocalizationContext,
} from './localization';

export const ThemeContext = createContext<{
  theme: Accessor<string>;
  setTheme: Setter<string>;
  themes: Iterable<string>;
}>();
export const ThemeProvider: Component<{
  themes: Iterable<string>;
  defaultTheme: string;
  children: any;
}> = (props) => {
  // detect theme initially from local storage
  const [theme, setTheme] = createSignal((() => {
    const theme = localStorage.getItem('theme');
    return (theme && Array.from(props.themes).indexOf(theme) >= 0 ? theme : props.defaultTheme);
  })());

  // update current theme in local storage and document class
  createEffect(() => {
    localStorage.setItem('theme', theme());
    for(const classItem of document.body.classList) {
      if(classItem.startsWith('theme-')) {
        document.body.classList.remove(classItem);
      }
    }
    document.body.classList.add(`theme-${theme()}`);
  });

  return (
    <ThemeContext.Provider value={{
      theme,
      setTheme,
      themes: props.themes,
    }}>{
      props.children
    }</ThemeContext.Provider>
  );
};

export const ThemeSelector: Component = () => {
  const { theme: currentTheme, setTheme, themes } = useContext(ThemeContext)!;
  const { t } = useContext(LocalizationContext)!;
  return (
    <Dropdown title={<><FaIcon solid circle-half-stroke weak inline />{t('theme')}</>} items={
      Array.from(themes).map((theme) => ({
        key: theme,
        title: <><Show when={theme == currentTheme()} fallback={<FaIcon regular circle />}><FaIcon regular circle-dot /></Show> {t(`theme.${theme}`)}</>,
      }))
    } onSelect={setTheme} />
  );
};
