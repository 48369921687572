import {
  createContext,
  createEffect,
  createResource,
  createSignal,
  Component,
  Accessor,
  Resource,
  JSXElement,
} from 'solid-js';
import {
  Api, SessionInfo,
} from './api-generated';
import config from '../config';
import { makeAwaitableVar } from './basic';

export type AuthStatus = 'processing' | 'error' | 'authenticated' | 'not_authenticated';

export type CallApiOptions = {
  path: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  params?: any;
  body?: any;
  auth?: boolean;
  refresh?: number;
};

export type AuthProvider = Parameters<Api["default"]["postAuthExternalStart"]>[0]["provider"];
export type AuthIntent = Parameters<Api["default"]["postAuthExternalStart"]>[0]["intent"];

export const AuthContext = createContext<{
  status: Accessor<AuthStatus>;
  authToken: () => Promise<string>;
  providers: AuthProvider[];
  startAuth: (provider: AuthProvider, intent: AuthIntent) => Promise<void>;
  finishAuth: (provider: AuthProvider) => Promise<{
    intent: AuthIntent | "login";
    nextUrl: string | null;
  }>;
  cancelAuth: () => Promise<void>;
  api: Api["default"];
  tokenlessApi: Api["default"];
  session: Resource<SessionInfo | null>;
  refetchSession: () => void;
  authRequired: Accessor<boolean>;
}>();
export const AuthProvider: Component<{
  children: JSXElement;
}> = (props) => {
  const [status, setStatus] = createSignal<AuthStatus>('processing');

  const [authToken, setAuthToken] = makeAwaitableVar<string>();

  const [authRequired, setAuthRequired] = createSignal<boolean>(false);

  const api = (new Api({
    BASE: config.apiUrl,
    TOKEN: async () => await authToken(() => {
      setAuthRequired(true);
    }),
  })).default;

  const tokenlessApi = (new Api({
    BASE: config.apiUrl,
  })).default;
  const [session, { refetch: refetchSession }] = createResource(async () => {
    try {
      return await tokenlessApi.getAuthSession();
    }
    catch(e) {
      return null;
    }
  });

  createEffect(() => {
    if(session.state === 'ready') {
      const sessionResult = session();
      if(sessionResult?.userInfo) {
        setAuthToken(sessionResult.authToken);
        setStatus('authenticated');
      } else {
        setStatus('not_authenticated');
      }
    } else if(session.state === 'errored') {
      setStatus('error');
    }
  });

  const startAuth = async (provider: AuthProvider, intent: AuthIntent) => {
    try {
      const {
        url,
        postParams,
        token,
      } = await tokenlessApi.postAuthExternalStart({
        provider,
        intent,
      });
      localStorage.setItem('external_auth_token', token);
      localStorage.setItem('auth_redirect_url', `${window.location.pathname}${window.location.search}`);
      if(Array.isArray(postParams) && postParams.length > 0) {
        const form = document.createElement('form');
        form.style.display = 'none';
        document.body.appendChild(form);
        form.action = url;
        form.method = 'POST';
        for(const param of postParams) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = param[0];
          input.value = param[1];
          form.appendChild(input);
        }
        form.submit();
      } else {
        window.location.href = url;
      }
    }
    catch(e) {
      setStatus('error');
    }
  };

  const finishAuth = async (provider: AuthProvider): Promise<{
      intent: AuthIntent | "login";
      nextUrl: string | null;
    }> => {
    const process = async ({ code, state }: {
      code: string | null;
      state: string | null;
    }): Promise<{
      intent: AuthIntent | "login";
      nextUrl: string | null;
    }> => {
      const externalAuthToken = localStorage.getItem('external_auth_token');
      localStorage.removeItem('external_auth_token');
      const authRedirectUrl = localStorage.getItem('auth_redirect_url');
      localStorage.removeItem('auth_redirect_url');
      if(code && state && externalAuthToken && state === externalAuthToken) {
        const credentialInfo = await tokenlessApi.postAuthExternalFinish({
          token: state,
          code,
        });
        if(credentialInfo.hasUser) {
          await tokenlessApi.postUsersAuth({
            token: credentialInfo.token,
          });
          refetchSession();
          return {
            intent: 'login',
            nextUrl: authRedirectUrl,
          };
        } else {
          switch(credentialInfo.intent) {
          case 'register': {
            await tokenlessApi.postUsersRegister({
              token: credentialInfo.token,
            });
            refetchSession();
            return {
              intent: 'register',
              nextUrl: authRedirectUrl,
            };
          }
          case 'link':
            await tokenlessApi.postUsersMeCredentials({
              token: credentialInfo.token,
            });
            refetchSession();
            return {
              intent: 'link',
              nextUrl: authRedirectUrl,
            };
          default:
            throw 'unknown_intent';
          }
        }
      } else {
        throw 'wrong_finish_auth_params';
      }
    };

    switch(provider) {
    case 'github':
    case 'gitlab':
    case 'twitch':
    case 'discord':
      {
        const params = new URLSearchParams(window.location.search);
        return await process({
          code: params.get('code'),
          state: params.get('state'),
        });
      }
      break;
    case 'steam':
      {
        const params = new URLSearchParams(window.location.search);
        return await process({
          code: JSON.stringify(Object.fromEntries(params)),
          state: params.get('state'),
        });
      }
      break;
    case 'itch':
      {
        const params = new URLSearchParams(window.location.hash.slice(1));
        return await process({
          code: params.get('access_token'),
          state: params.get('state'),
        });
      }
      break;
    default:
      throw 'unsupported_provider';
    }
  };

  const cancelAuth = async () => {
    await api.postAuthClear();

    window.location.href = '/';
  };

  return (
    <AuthContext.Provider value={{
      status,
      authToken,
      providers: config.authProviders as AuthProvider[],
      startAuth,
      finishAuth,
      cancelAuth,
      api,
      tokenlessApi,
      session,
      refetchSession,
      authRequired,
    }}>{
      props.children
    }</AuthContext.Provider>
  );
};
