import { DateTime } from 'luxon';
import {
  Accessor,
  Component,
  For,
  JSXElement,
  Match,
  Show,
  Switch,
  createMemo,
  useContext,
} from 'solid-js';
import config from '../config';
import { ProjectInfo } from './api-generated';
import {
  AuthProvider,
} from './auth';
import {
  Button,
  callDialog,
  Dropdown,
  FaIcon,
} from './basic';
import {
  LocalizationContext,
} from './localization';
import { AppPage } from './routing';
import { OkDialog } from '../dialogs/basic';

export const CredentialProviderLabel: Component<{
  provider: AuthProvider;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  return (
    <>
      <FaIcon inline brands {...{[({
        itch: 'itch-io',
      } as {
        [k: string]: string;
      })[props.provider] ?? props.provider]: true}} />
      <span>{ t(`auth.provider.${props.provider}`) }</span>
    </>
  );
};

export const deployProviders = ['steam', 'itch'] as const;
export type DeployProvider = typeof deployProviders[number];

export const DeployProviderIcon: Component<{
  provider: DeployProvider;
  big?: boolean;
}> = (props) =>
  <FaIcon inline brands {...{[({
    itch: 'itch-io',
  } as {
    [k: string]: string;
  })[props.provider] ?? props.provider]: true}} fa-2xl={props.big} />
;

export const DeployProviderLabel: Component<{
  provider: DeployProvider;
  icon?: boolean;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  return (
    <>
      <Show when={props.icon ?? true}>
        <DeployProviderIcon provider={props.provider} />
      </Show>
      <span>{ t(`deploy_credential_provider.${props.provider}`) }</span>
    </>
  );
};

export const ProviderImage: Component<{
  provider: AuthProvider | DeployProvider;
  url: string;
  class?: string;
  alt?: string;
}> = (props) =>
  <img
    class={props.class}
    src={props.url}
    alt={props.alt}
    // do not use crossorigin=anonymous when provider does not support proper CORS for avatars
    crossorigin={['gitlab', 'itch'].indexOf(props.provider) >= 0 ? undefined : "anonymous"}
    referrerpolicy="no-referrer"
  />
;

export const deployProvidersInfos: {
  [provider: string]: {
    siteInputPlaceholder: string;
    fromSiteInput: (siteInput: string) => string | null;
    siteTitle: (site: string) => string;
  };
} = {
  'steam': {
    siteInputPlaceholder: 'https://store.steampowered.com/app/{appId}',
    fromSiteInput: (siteInput: string) => {
      const regex = /^https?:\/\/store\.steampowered\.com\/app\/([0-9]+)(?:[/?].*)?$/;
      if(!regex.test(siteInput)) return null;
      return siteInput.replace(regex, (_match: string, appId: string) => appId);
    },
    siteTitle: (site) => site,
  },
  'itch': {
    siteInputPlaceholder: 'https://{dev}.itch.io/{game}',
    fromSiteInput: (siteInput: string) => {
      const regex = /^https?:\/\/([^./]+)\.itch\.io\/([^/?]+)(?:[/?].*)?$/;
      if(!regex.test(siteInput)) return null;
      return siteInput.replace(regex, (_match: string, developer: string, game: string) => `${developer}/${game}`);
    },
    siteTitle: (site) => {
      const [siteOwner, siteApp] = site.split('/');
      return `${siteOwner}.itch.io/${siteApp}`;
    },
  },
};

export const ResourceFallback: Component<{
  resource: {
    loading: boolean;
    error: any;
  };
  page?: boolean;
  loading?: any;
  error?: any;
  empty?: any;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  return (
    <Switch fallback={props.empty ?? (props.page ? <PageNotFound /> : <div class="empty">{t('empty')}</div>)}>
      <Match when={props.resource.loading}>{props.loading ?? (props.page ? <PageLoading /> : <div class="loading">{t('loading')}</div>)}</Match>
      <Match when={props.resource.error}>{props.error ?? <div class="error">{t('error')}</div>}</Match>
    </Switch>
  );
};

export const FormatDate: Component<{
  date: Date;
  format?: 'date' | 'datetime' | 'relative_date' | 'relative_datetime';
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  const value = createMemo(() => {
    const value = DateTime.fromJSDate(props.date);
    switch(props.format ?? 'relative_datetime') {
    case 'date':
      return value.toLocaleString({
        dateStyle: 'short',
      }, {
        locale: t('locale'),
      });
    case 'datetime':
      return value.toLocaleString({
        dateStyle: 'short',
        timeStyle: 'short',
      }, {
        locale: t('locale'),
      });
    case 'relative_date':
      return value.toRelativeCalendar({
        locale: t('locale'),
      });
    case 'relative_datetime':
      return value.toRelative({
        locale: t('locale'),
      });
    }
  });

  return <>{value()}</>;
};

export const PageLoading: Component = (props) => {
  const { t } = useContext(LocalizationContext)!;

  return <>
    <div class="loading">{t('loading')}</div>
    <div class="footer">
    </div>
  </>;
};

export const PageNotFound: Component = (props) => {
  const { t } = useContext(LocalizationContext)!;

  return <>
    <AppPage
      title="???"
      breadcrumb="???"
      uiClass="page_not_found"
    />
    <h1>{t('page_not_found.header')}</h1>
    <div class="message">{t('page_not_found.message')}</div>
    <div class="message">{t('page_not_found.message2')}</div>
    <ul class="items">
      <For each={t('page_not_found.items')}>{(item) => <li>{item}</li>}</For>
    </ul>
    <div class="footer">
    </div>
  </>;
};

export type ProjectUserRole = NonNullable<ProjectInfo["userRole"]>;

export const projectUserRoles: ProjectUserRole[] = ['tester', 'developer', 'admin'];
export const projectUserRoleIndexes: {
  [role in ProjectUserRole]: number;
} = {
  tester: 0,
  developer: 1,
  admin: 2,
};
export const compareProjectUserRoles = (a: ProjectUserRole, b: ProjectUserRole) => projectUserRoleIndexes[a] - projectUserRoleIndexes[b];

export type ProjectUserAction = ProjectInfo["userPossibleActions"][0];

export const ProjectActionsButtons = <K, T extends {
  key: K;
  title: any;
  danger?: boolean;
  action: ProjectUserAction;
}>(props: {
  project: Accessor<ProjectInfo>;
  items: T[];
  title: any;
  onSelect?: (key: K) => any;
}) => {
  const items = createMemo(() => props.items.filter((action) => props.project().userPossibleActions.indexOf(action.action) >= 0));
  return (
    <Show when={items().length > 0}>
      <div class="buttons align_end">
        <Dropdown items={items()} title={props.title} onSelect={props.onSelect} />
      </div>
    </Show>
  );
};

export const ReportLink: Component<{
  title: JSXElement;
  subject: string;
  body: string;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  return (
    <Button light danger onClick={() => callDialog(OkDialog, {
      header: t('report_content.dialog.header'),
      message: t('report_content.dialog.message')(config.abuseEmail, <a href={`mailto:${config.abuseEmail}?subject=${encodeURIComponent(props.subject)}&body=${encodeURIComponent(props.body)}`}>{t('report_content.dialog.link')}</a>),
    })}>{props.title}</Button>
  );
};
