import {
  Component,
  createResource,
  For,
  Show,
  useContext,
} from 'solid-js';
import {
  AppPage,
  AuthContext,
  AuthProvider,
  Button,
  callDialog,
  CredentialProviderLabel,
  DeployProviderIcon,
  DeployProviderLabel,
  DialogParams,
  Dropdown,
  FaIcon,
  FormatDate,
  LocalizationContext,
  ProviderImage,
  ResourceFallback,
} from '../../components';
import {
  DeployCredentialInfo,
  UserCredentialInfo,
} from '../../components/api-generated';
import {
  AddDeployCredentialDialog,
} from '../../dialogs/add_deploy_credential';
import {
  ConfirmDialog,
  OkCopyDialog,
  PromptDialog,
  showErrorScope,
} from '../../dialogs/basic';
import { linkHome } from './links';

export const RouteMe: Component = () => {
  const { t } = useContext(LocalizationContext)!;
  const { session, refetchSession, api, providers, startAuth } = useContext(AuthContext)!;

  const [sessions, { refetch: refetchSessions }] = createResource(async () => {
    return await api.getUsersMeSessions();
  });
  const [credentials, { refetch: refetchCredentials }] = createResource(async () => {
    return await api.getUsersMeCredentials();
  });
  const [deployCredentials, { refetch: refetchDeployCredentials }] = createResource(async () => {
    return await api.getUsersMeDeployCredentials();
  });

  const onMeRename = async () => {
    const title = await callDialog(PromptDialog, {
      size: 'small',
      header: t('me.rename_dialog.header'),
      label: t('me.rename_dialog.label_title'),
      value: session()?.userInfo?.title ?? '',
      ok: t('me.rename_dialog.ok'),
    });
    if(title !== undefined) {
      await api.patchUsersMe({
        requestBody: {
          title,
        },
      });
      refetchSession();
    }
  };

  const DialogLinkCredential: Component<DialogParams<AuthProvider | null>> = (props) => {
    return <>
      <props.dialog class="sheet small ui link_credential">
        <h1>{t('me.credential.link_dialog.header')}</h1>
        <p>{t('me.credential.link_dialog.message')}</p>
        <div class="buttons">
          <For each={providers}>{
            (provider) =>
              <button class="button" onClick={[props.resolve, provider]}><CredentialProviderLabel provider={provider} /></button>
          }</For>
        </div>
        <div class="footer">
          <div class="buttons align_end">
            <Button autofocus onClick={() => props.resolve(null)}>{t('cancel')}</Button>
          </div>
        </div>
      </props.dialog>
    </>;
  };

  const onLinkCredential = async () => {
    const provider = await callDialog(DialogLinkCredential, {});
    if(provider) {
      startAuth(provider, 'link');
    }
  };

  const onUnlinkCredential = async (credential: UserCredentialInfo) => {
    if(!await callDialog(ConfirmDialog, {
      header: t('me.credential.unlink_dialog.header'),
      message: t('me.credential.unlink_dialog.are_you_sure')(credential),
      ok: t('me.credential.unlink_dialog.ok'),
      focus: 'cancel',
      danger: true,
    })) return;
    await showErrorScope(async () => {
      await api.deleteUsersMeCredentials(credential);
      refetchCredentials();
    });
  };

  const onChangeCredentialIsPublic = async (credential: UserCredentialInfo, isPublic: boolean) => {
    await showErrorScope(async () => {
      await api.patchUsersMeCredentials({
        provider: credential.provider,
        name: credential.name,
        requestBody: {
          isPublic,
        },
      });
      refetchCredentials();
    });
  };

  const onDeleteSession = async (apiKeyId: string) => {
    if(!await callDialog(ConfirmDialog, {
      header: t('me.sessions.delete_dialog.header'),
      message: t('me.sessions.delete_dialog.message'),
      ok: t('me.sessions.delete_dialog.ok'),
      focus: 'cancel',
      danger: true,
    })) return;
    await showErrorScope(async () => {
      await api.deleteUsersMeSessions({
        session: apiKeyId,
      });
      refetchSessions();
    });
  };

  const onCreateApiKey = async () => {
    await showErrorScope(async () => {
      const newUserSession = await api.postUsersMeSessions({
        requestBody: {
          // no expiration
        },
      });
      await callDialog(OkCopyDialog, {
        header: t('me.api_tokens.create_dialog.header'),
        message: t('me.api_tokens.create_dialog.message'),
        text: newUserSession.token,
        note: t('me.api_tokens.create_dialog.note'),
      });
      refetchSessions();
    });
  };

  const onDeleteApiKey = async (apiKeyId: string) => {
    if(!await callDialog(ConfirmDialog, {
      header: t('me.api_tokens.delete_dialog.header'),
      message: t('me.api_tokens.delete_dialog.message'),
      ok: t('me.api_tokens.delete_dialog.ok'),
      focus: 'cancel',
      danger: true,
    })) return;
    await showErrorScope(async () => {
      await api.deleteUsersMeSessions({
        session: apiKeyId,
      });
      refetchSessions();
    });
  };

  const onAddDeployCredential = async () => {
    const credentialId = await callDialog(AddDeployCredentialDialog, {});
    if(credentialId) {
      refetchDeployCredentials();
    }
  };

  const onDeleteDeployCredential = async (deployCredential: DeployCredentialInfo) => {
    if(await callDialog(ConfirmDialog, {
      header: t('me.deploy_credential.delete_dialog.header'),
      message: t('me.deploy_credential.delete_dialog.message')(deployCredential),
      ok: t('me.deploy_credential.delete_dialog.ok'),
      focus: 'cancel',
      danger: true,
    })) {
      await api.deleteUsersMeDeployCredentials({
        deployCredential: deployCredential.id,
      });
      refetchDeployCredentials();
    }
  };

  const onMeAction = async (key: string) => showErrorScope(async () => {
    switch(key) {
    case 'delete':
      if(await callDialog(ConfirmDialog, {
        header: t('me.delete.dialog.header'),
        message: t('me.delete.dialog.message')(session()?.userInfo?.title),
        ok: t('me.delete.dialog.ok'),
        focus: 'cancel',
        danger: true,
      })) {
        await api.deleteUsersMe();
        window.location.href = linkHome;
      }
      break;
    }
  });

  return <>
    <AppPage
      title={t('route.me')}
      breadcrumb={t('route.me')}
      uiClass="me"
    />
    <h1>{session()?.userInfo?.title}</h1>
    <div class="buttons">
      <Button onClick={onMeRename}>{t('me.rename')}</Button>
    </div>
    <h2>{t('me.credentials')}</h2>
    <div class="hint">{t('me.credentials.hint')}</div>
    <div class="list credentials">
      <For each={credentials()} fallback={
        <div>
          <ResourceFallback resource={credentials} empty={<div class="empty">{t('me.credentials.empty')}</div>} />
        </div>
      }>{(credential) =>
        <div>
          <Show when={credential.profilePictureUrl}>
            <ProviderImage
              provider={credential.provider}
              url={credential.profilePictureUrl!}
              class="profile_picture"
              alt={t('me.credentials.profile_picture.alt')(credential.provider)}
            />
          </Show>
          <Show when={credential.profileUrl} fallback={<span class="profile_link">{credential.title}</span>}>
            <a class="profile_link" href={credential.profileUrl} target="_blank">{credential.title}</a>
          </Show>
          <div class="profile_provider"><CredentialProviderLabel provider={credential.provider} /></div>
          <div class="created">{t('me.credentials.created')(<FormatDate date={new Date(credential.created)} />)}</div>
          <div class="last_used">{t('me.credentials.last_used')(<FormatDate date={new Date(credential.lastUsed)} />)}</div>
          <div class="buttons">
            <Button danger onClick={[onUnlinkCredential, credential]}>{t('me.credential.unlink')}</Button>
          </div>
        </div>
      }</For>
      <div>
        <div class="footer">
          <Button onClick={onLinkCredential}>{t('me.credential.link_another')}</Button>
        </div>
      </div>
    </div>
    <h2>{t('me.sessions')}</h2>
    <div class="hint">{t('me.sessions.hint')}</div>
    <div class="list sessions">
      <For each={sessions()?.filter((session) => session.requireSession)} fallback={
        <div>
          <ResourceFallback resource={sessions} empty={<div class="empty">{t('me.sessions.empty')}</div>} />
        </div>
      }>{(userSession) =>
        <div>
          <FaIcon solid id-card fa-2xl />
          <div class="current">{userSession.id == session()?.id ? t('me.sessions.current') : null}</div>
          <div class="started">{t('me.sessions.started')(<FormatDate date={new Date(userSession.started)} />)}</div>
          <div class="last_auth">{userSession.lastAuth ? t('me.sessions.last_auth')(<FormatDate date={new Date(userSession.lastAuth)} />) : null}</div>
          <div class="buttons">
            <Button onClick={[onDeleteSession, userSession.id]} danger>{t('me.sessions.delete')}</Button>
          </div>
        </div>
      }</For>
    </div>
    <h2>{t('me.api_tokens')}</h2>
    <div class="hint">{t('me.api_tokens.hint')}</div>
    <div class="list sessions">
      <For each={sessions()?.filter((session) => !session.requireSession)} fallback={
        <div>
          <ResourceFallback resource={sessions} empty={<div class="empty">{t('me.api_tokens.empty')}</div>} />
        </div>
      }>{(userSession) =>
        <div>
          <FaIcon solid key fa-2xl />
          <div class="started">{t('me.api_tokens.started')(<FormatDate date={new Date(userSession.started)} />)}</div>
          <div class="expiration">{userSession.expiration ? t('me.api_tokens.expires')(<FormatDate date={new Date(userSession.expiration)} />) : t('me.api_tokens.no_expiration')}</div>
          <div class="buttons">
            <Button onClick={[onDeleteApiKey, userSession.id]} danger>{t('me.api_tokens.delete')}</Button>
          </div>
        </div>
      }</For>
      <div>
        <div class="footer">
          <Button onClick={onCreateApiKey}>{t('me.api_tokens.create')}</Button>
        </div>
      </div>
    </div>
    <h2>{t('me.deploy_credentials')}</h2>
    <div class="hint">{t('me.deploy_credentials.hint')}</div>
    <div class="list deploy_credentials">
      <For each={deployCredentials()} fallback={
        <div>
          <ResourceFallback resource={deployCredentials} empty={<div class="empty">{t('me.deploy_credentials.empty')}</div>} />
        </div>
      }>{(deployCredential) =>
        <div>
          <DeployProviderIcon provider={deployCredential.provider} big />
          <div class="title">{deployCredential.title}</div>
          <div class="provider"><DeployProviderLabel icon={false} provider={deployCredential.provider} /></div>
          <div class="buttons">
            <Button danger onClick={[onDeleteDeployCredential, deployCredential]}>{t('me.deploy_credential.delete')}</Button>
          </div>
        </div>
      }</For>
      <div>
        <div class="footer">
          <Button onClick={onAddDeployCredential}>{t('me.deploy_credential.add')}</Button>
        </div>
      </div>
    </div>
    <h2>{t('me.public_profile')}</h2>
    <h3>{t('me.public_profile.public_credentials')}</h3>
    <div class="hint">{t('me.public_profile.public_credentials.hint')}</div>
    <div class="list public_credentials">
      <For each={credentials()} fallback={
        <div>
          <ResourceFallback resource={credentials} empty={<div class="empty">{t('me.credentials.empty')}</div>} />
        </div>
      }>{(credential) =>
        <label>
          <input type="checkbox" checked={credential.isPublic} onClick={(e) => onChangeCredentialIsPublic(credential, e.currentTarget.checked)} />
          <Show when={credential.profileUrl} fallback={<div class="credential"><CredentialProviderLabel provider={credential.provider} /></div>}>
            <a class="credential" href={credential.profileUrl} rel="ugc nofollow" target="_blank"><CredentialProviderLabel provider={credential.provider} /></a>
          </Show>
        </label>
      }</For>
    </div>
    <div class="footer">
      <div class="buttons align_end">
        <Dropdown title={t('me.manage')} items={[
          {
            key: 'delete',
            title: t('me.delete'),
            danger: true,
          },
        ]} onSelect={onMeAction} />
      </div>
    </div>
  </>;
};
