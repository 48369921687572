import {
  List as IList,
  Map as IMap,
  Set as ISet,
} from 'immutable';
import {
  DirectoryEntry,
  Entry,
  FileEntry,
  FileEntryChunk,
  SymlinkEntry,
} from './entries';
import { buffer2hex } from './utils';

const chunkSize = 1024;
const textEncoder = new TextEncoder();
const textDecoder = new TextDecoder();
const indexMagic = textEncoder.encode("KCAI");

enum EntryType {
  Directory = 0,
  File = 1,
  Symlink = 2,
}

export enum ChunkingAlgorithm {
  None = 0,
  Gear17 = 1,
}

export enum CompressionAlgorithm {
  None = 0,
  Zstd = 1,
}

enum EntryFileModes {
  Executable = 1,
}

export type ArchiveChunksIndex = ISet<string>;

export const calculateArchiveChunksIndex = (root: DirectoryEntry): ArchiveChunksIndex => ISet<string>().withMutations((chunks) => {
  function collectEntryChunks(entry: Entry) {
    if(entry instanceof DirectoryEntry) {
      collectEntriesChunks(entry.entries());
    }
    if(entry instanceof FileEntry) {
      if(entry.chunks) {
        for(const chunk of entry.chunks) {
          chunks = chunks.add(buffer2hex(chunk.hash));
        }
      }
    }
  }
  function collectEntriesChunks(entries: IMap<string, Entry>) {
    for(const [_entryName, entry] of entries) {
      collectEntryChunks(entry);
    }
  }

  collectEntriesChunks(root.entries());
});

class ArchiveBuilder {
  constructor(chunkingAlgorithm: ChunkingAlgorithm, compressionAlgorithm: CompressionAlgorithm, baseChunkSet?: ArchiveChunksIndex) {
    this.chunkingAlgorithm = chunkingAlgorithm;
    this.compressionAlgorithm = compressionAlgorithm;
    this.baseChunkSet = baseChunkSet;

    this.writeNumber(this.chunkingAlgorithm);
    this.writeNumber(this.compressionAlgorithm);
  }

  private checkSpace() {
    if(this.indexChunks.isEmpty() || this.indexChunkPosition >= chunkSize) {
      const buffer = new ArrayBuffer(chunkSize);
      this.indexChunks = this.indexChunks.push(buffer);
      this.indexChunkView = new DataView(buffer);
      this.indexChunkArray = new Uint8Array(buffer);
      this.indexChunkPosition = 0;
    }
  }

  writeByte(byte: number) {
    this.checkSpace();
    this.indexChunkView!.setUint8(this.indexChunkPosition++, byte);
  }

  writeUint8Array(bytes: Uint8Array, start: number = 0, end: number = bytes.byteLength) {
    while(start < end) {
      this.checkSpace();
      const size = Math.min(end - start, chunkSize - this.indexChunkPosition);
      this.indexChunkArray!.set(bytes.slice(start, start + size), this.indexChunkPosition);
      this.indexChunkPosition += size;
      start += size;
    }
  }

  writeNumber(n: number) {
    do {
      let b = n & 0x7F;
      n >>= 7;
      if(n > 0) b |= 0x80;
      this.writeByte(b);
    } while(n > 0);
  }
  writeNumberWithSameSizeAs(n: number, ref: number) {
    do {
      let b = n & 0x7F;
      n >>= 7;
      ref >>= 7;
      if(n > 0 || ref > 0) b |= 0x80;
      this.writeByte(b);
    } while(n > 0 || ref > 0);
  }

  async writeEntries(entries: IMap<string, Entry>) {
    this.writeNumber(entries.size);
    for(const [encodedName, entry] of entries.entrySeq().map<[Uint8Array, Entry]>(([name, entry]) => [textEncoder.encode(name), entry]).sort(compareByEncodedName)) {
      this.writeNumber(encodedName.byteLength);
      this.writeUint8Array(encodedName);
      await this.writeEntry(entry);
    };
  }

  async writeEntry(entry: Entry) {
    if(entry instanceof DirectoryEntry) {
      this.writeNumber(EntryType.Directory);
      await this.writeEntries(entry.entries());
    }
    else if(entry instanceof FileEntry) {
      this.writeNumber(EntryType.File);
      this.writeNumber(entry.size);
      this.writeNumber(entry.mode());
      if(this.chunkingAlgorithm != ChunkingAlgorithm.None) {
        // create chunks if needed
        if(entry.size > 0 && (entry.chunks?.length ?? 0) <= 0 && entry.blob) {
          const chunks: FileEntryChunk[] = [];
          for await(const chunk of gear17Chunking(entry.blob)) {
            chunks.push({
              ...chunk,
              compressedSize: 0,
            });
          }
          entry.chunks = chunks;
        }

        this.writeNumber(entry.chunks?.length ?? 0);
        if(entry.chunks) {
          for(let i = 0; i < entry.chunks.length; ++i) {
            const chunk = entry.chunks[i];
            this.writeUint8Array(new Uint8Array(chunk.hash));
            // skip chunk if presented in base chunk set
            if(this.baseChunkSet && this.baseChunkSet.has(buffer2hex(chunk.hash))) {
              this.writeNumber(0);
            } else {
              this.writeNumber(chunk.size);
              if(this.compressionAlgorithm != CompressionAlgorithm.None) {
                this.writeNumberWithSameSizeAs(chunk.compressedSize, chunk.size);
              }
            }
          }
        }
      }
      if(entry.blob) {
        if(this.chunkingAlgorithm != ChunkingAlgorithm.None && entry.chunks) {
          let offset = 0;
          for(let i = 0; i < entry.chunks.length; ) {
            let j;
            let size = 0;
            for(j = i; j < entry.chunks.length && !(this.baseChunkSet && this.baseChunkSet.has(buffer2hex(entry.chunks[j].hash))); ++j) {
              size += entry.chunks[j].size;
            }
            if(i >= j) {
              offset += entry.chunks[i++].size;
              continue;
            }
            this.filesBlobs.push(entry.blob.slice(offset, offset + size));
            offset += size;
            i = j;
          }
        }
        else {
          this.filesBlobs.push(entry.blob);
        }
      }
    }
    else if(entry instanceof SymlinkEntry) {
      this.writeNumber(EntryType.Symlink);
      const encodedLink = textEncoder.encode(entry.link);
      this.writeNumber(encodedLink.byteLength);
      this.writeUint8Array(encodedLink);
    }
  }

  async finalize(): Promise<Blob> {
    let chunks = this.indexChunks;
    if(!this.indexChunks.isEmpty() && this.indexChunkPosition < chunkSize) {
      chunks = chunks.set(chunks.size - 1, chunks.last()!.slice(0, this.indexChunkPosition));
    }

    // prepend chunk with index size
    {
      const buffer = new ArrayBuffer(chunkSize);
      this.indexChunkView = new DataView(buffer);
      this.indexChunkArray = new Uint8Array(buffer);
      this.indexChunkPosition = 0;

      let indexSize = 0;
      for(const chunk of chunks)
        indexSize += chunk.byteLength;
      this.writeNumber(indexSize);

      chunks = chunks.insert(0, buffer.slice(0, this.indexChunkPosition));
    }

    // calculate index hash
    const indexHash = await crypto.subtle.digest('SHA-256', await new Blob(chunks.toArray()).arrayBuffer());
    // prepend chunk with magic and hash
    {
      const buffer = new ArrayBuffer(chunkSize);
      this.indexChunkView = new DataView(buffer);
      this.indexChunkArray = new Uint8Array(buffer);
      this.indexChunkPosition = 0;

      this.writeUint8Array(indexMagic);
      this.writeUint8Array(new Uint8Array(indexHash));

      chunks = chunks.insert(0, buffer.slice(0, this.indexChunkPosition));
    }

    return new Blob([
      ...chunks.toArray(),
      ...this.filesBlobs,
    ]);
  }

  private chunkingAlgorithm: ChunkingAlgorithm;
  private compressionAlgorithm: CompressionAlgorithm;
  private baseChunkSet?: ArchiveChunksIndex;
  private indexChunks = IList<ArrayBuffer>();
  private indexChunkView: DataView | null = null;
  private indexChunkArray: Uint8Array | null = null;
  private indexChunkPosition = 0;
  private filesBlobs: Blob[] = [];
}

export const packEntriesIntoArchive = async (root: DirectoryEntry, chunkingAlgorithm: ChunkingAlgorithm = ChunkingAlgorithm.Gear17, compressionAlgorithm: CompressionAlgorithm = CompressionAlgorithm.None, baseChunkSet?: ArchiveChunksIndex): Promise<Blob> => {
  const builder = new ArchiveBuilder(chunkingAlgorithm, compressionAlgorithm, baseChunkSet);
  await builder.writeEntries(root.entries());
  return builder.finalize();
};

type EntryCallback = (entry: Entry) => EntryCallback | undefined;

export class ArchiveReader {
  constructor(reader: ReadableStreamDefaultReader) {
    this.reader = reader;
  }

  async readIndex(entryCallback?: EntryCallback): Promise<DirectoryEntry> {
    // check magic
    for(let i = 0; i < indexMagic.byteLength; ++i) {
      if(await this.readByte() != indexMagic.at(i)) throw 'wrong_magic';
    }

    // read hash
    const indexHash = await this.readArray(32);
    const indexOffset = this.totalReadSize;
    // read size
    const indexSize = await this.readNumber();
    const indexOffset2 = this.totalReadSize;
    // get encoded size directly from chunk, hack, but must be OK
    const indexSizeData = this.chunk!.slice(indexOffset, indexOffset2);

    // check hash
    const indexData = await this.readArray(indexSize);
    {
      const indexAndSizeData = await new Blob([indexSizeData, indexData]).arrayBuffer();

      const indexDataHash = new Uint8Array(await crypto.subtle.digest('SHA-256', indexAndSizeData));
      for(let i = 0; i < 32; ++i)
        if(indexHash.at(i) != indexDataHash.at(i))
          throw 'wrong_index_hash';
    }

    // set up reading from index
    await this.putChunk(indexData);
    this.totalReadSize -= indexData.byteLength;

    // read header
    this.chunkingAlgorithm = await this.readNumber();
    this.compressionAlgorithm = await this.readNumber();

    // read entries
    const entry = new DirectoryEntry('');
    entry.setEntries(await this.readEntries(entryCallback));

    // check position
    if(indexOffset2 + indexSize != this.totalReadSize)
      throw 'wrong_index_size';

    // fix file offsets
    for(const fileEntry of this.fileEntries) {
      fileEntry.offset! += this.totalReadSize;
    }

    return entry;
  }

  // returns data stream
  // the stream must be consumed before reading anything else
  readDataStream(size: number): AsyncGenerator<Uint8Array> {
    return async function*(self: ArchiveReader): AsyncGenerator<Uint8Array> {
      let position = 0;
      while(position < size) {
        if(self.chunkOffset >= self.chunkSize) await self.readChunk();
        const toReadSize = Math.min(size - position, self.chunkSize - self.chunkOffset);
        const chunkSlice = self.chunk!.slice(self.chunkOffset, self.chunkOffset + toReadSize);
        self.chunkOffset += toReadSize;
        position += toReadSize;
        yield chunkSlice;
      }
    }(this);
  }

  private async readEntries(entryCallback?: EntryCallback): Promise<IMap<string, Entry>> {
    let entries = IMap<string, Entry>();
    const entriesCount = await this.readNumber();
    if(entriesCount >= 0x100000) throw 'too_many_entries';
    for(let i = 0; i < entriesCount; ++i) {
      const name = textDecoder.decode(await this.readArray(await this.readNumber()));
      const entry = await this.readEntry(name, entryCallback);
      entries = entries.set(name, entry);
    }
    return entries;
  }

  private async readEntry(name: string, entryCallback?: EntryCallback): Promise<Entry> {
    switch(await this.readNumber()) {
    case EntryType.Directory: {
      const entry = new DirectoryEntry(name);
      entry.setEntries(await this.readEntries(entryCallback?.(entry)));
      return entry;
    }
    case EntryType.File: {
      const size = await this.readNumber();
      const mode = await this.readNumber();
      const chunks: FileEntryChunk[] = [];
      if(this.chunkingAlgorithm != ChunkingAlgorithm.None) {
        const chunksCount = await this.readNumber();
        for(let i = 0; i < chunksCount; ++i) {
          const hash = await this.readArray(32);
          const size = await this.readNumber();
          let compressedSize = 0;
          if(size > 0 && this.compressionAlgorithm != CompressionAlgorithm.None) {
            compressedSize = await this.readNumber();
          }
          chunks.push({
            hash,
            size,
            compressedSize,
          });
        }
      }
      const entry = new FileEntry(name, {
        size,
        mode,
        chunks,
        offset: this.totalFilesSize,
      });
      this.totalFilesSize += size;
      this.fileEntries.push(entry);
      entryCallback?.(entry);
      return entry;
    }
    case EntryType.Symlink: {
      const link = textDecoder.decode(await this.readArray(await this.readNumber()));
      const entry = new SymlinkEntry(name, link);
      entryCallback?.(entry);
      return entry;
    }
    default:
      throw 'invalid_entry';
    }
  }

  private async readNumber(): Promise<number> {
    let n = 0, b, offset = 0;
    do {
      b = await this.readByte();
      n |= (b & 0x7F) << offset;
      offset += 7;
    } while(b & 0x80);
    return n;
  }

  private async readByte(): Promise<number> {
    if(this.chunkOffset >= this.chunkSize) await this.readChunk();
    ++this.totalReadSize;
    return this.chunk!.at(this.chunkOffset++)!;
  }

  public async readArray(size: number): Promise<Uint8Array> {
    const result = new Uint8Array(size);
    let readSize = 0;
    while(readSize < size) {
      if(this.chunkOffset >= this.chunkSize) await this.readChunk();
      const toReadSize = Math.min(size - readSize, this.chunkSize - this.chunkOffset);
      result.set(this.chunk!.slice(this.chunkOffset, this.chunkOffset + toReadSize), readSize);
      readSize += toReadSize;
      this.chunkOffset += toReadSize;
    }
    this.totalReadSize += readSize;
    return result;
  }

  private async putChunk(chunk: Uint8Array) {
    const chunks = [];
    chunks.push(chunk);
    if(this.chunk) chunks.push(this.chunk.slice(this.chunkOffset));
    this.chunk = new Uint8Array(await new Blob(chunks).arrayBuffer());
    this.chunkOffset = 0;
    this.chunkSize = this.chunk.byteLength;
  }

  private async readChunk() {
    const { value, done } = await this.reader.read();
    if(done || value.byteLength <= 0) throw 'archive_ended';
    this.chunk = value instanceof ArrayBuffer ? new Uint8Array(value) : value;
    this.chunkOffset = 0;
    this.chunkSize = this.chunk.byteLength;
  }

  private reader: ReadableStreamDefaultReader<Uint8Array>;
  private chunk: Uint8Array | undefined;
  private chunkOffset = 0;
  private chunkSize = 0;

  private fileEntries: FileEntry[] = [];

  chunkingAlgorithm: ChunkingAlgorithm | undefined;
  compressionAlgorithm: CompressionAlgorithm | undefined;

  totalReadSize = 0;
  totalFilesSize = 0;
};

// read only entries (index)
export const readEntriesFromArchive = async (url: string): Promise<DirectoryEntry> => {
  const response = await fetch(url, {
    mode: 'cors',
  });
  if(response.status !== 200) throw 'archive_fetch_failed';
  const reader = response.body!.getReader();
  const archiveReader = new ArchiveReader(reader);
  return await archiveReader.readIndex();
};

export type ArchiveContentIndexEntry = ArchiveContentIndexDirectoryEntry | ArchiveContentIndexFileEntry | ArchiveContentIndexSymlinkEntry;
export type ArchiveContentIndexEntries = {
  [name: string]: ArchiveContentIndexEntry;
};
export type ArchiveContentIndexDirectoryEntry = {
  e: ArchiveContentIndexEntries;
};
export type ArchiveContentIndexFileEntry = {
  b: number;
  s: number;
};
export type ArchiveContentIndexSymlinkEntry = {
  l: string;
};
export type ArchiveContentIndex = {
  e: ArchiveContentIndexEntries;
};

export const indexArchiveContent = (root: DirectoryEntry): ArchiveContentIndex => {
  const indexEntries = (entries: IMap<string, Entry>): ArchiveContentIndexEntries => entries.map(indexEntry).toObject();
  const indexEntry = (entry: Entry): ArchiveContentIndexEntry => {
    if(entry instanceof DirectoryEntry) {
      return {
        e: indexEntries(entry.entries()),
      };
    }
    if(entry instanceof FileEntry) {
      return {
        b: entry.offset!,
        s: entry.size,
      };
    }
    if(entry instanceof SymlinkEntry) {
      return {
        l: entry.link,
      };
    }
    throw 'invalid_entry';
  };
  return {
    e: indexEntries(root.entries()),
  };
};

export const compareByEncodedName = ([a]: [Uint8Array, any], [b]: [Uint8Array, any]) => {
  for(let i = 0; i < a.byteLength && i < b.byteLength; ++i) {
    if(a.at(i)! < b.at(i)!) return -1;
    if(a.at(i)! > b.at(i)!) return 1;
  }
  if(a.byteLength < b.byteLength) return -1;
  if(a.byteLength > b.byteLength) return 1;
  return 0;
}

type Chunk = {
  size: number;
  hash: ArrayBuffer;
};

// dumped from C++ generator
const gear17Table = new Uint32Array([
  0xd091bb5c, 0x22ae9ef6, 0xe7e1faee, 0xd5c31f79, 0x2082352c, 0xf807b7df, 0xe9d30005, 0x3895afe1, 0xa1e24bba, 0x4ee4092b, 0x18f86863, 0x8c16a625, 0x474ba8c4, 0x3039cd1a, 0x8c006d5f, 0xfe2d7810,
  0xf51f2ae7, 0xff1816e4, 0xf702ef59, 0xf7badafa, 0x285954a1, 0xb9d09511, 0xf878c4b3, 0xfb2a0137, 0xf508e4aa, 0x1c1fe652, 0x7c419418, 0xcc50aa59, 0xccdf2e5c, 0x4c0a1f3b, 0x2452a9dc, 0x01397d8d,
  0x6bf88c31, 0x1cca797a, 0xea6da4ae, 0xa3c78807, 0xcace1969, 0xe0e0d4ad, 0xf5a14bab, 0x80f00988, 0xa7de9f4c, 0xcc450cba, 0x0924668f, 0x5c7dc380, 0xd96089c5, 0x3640ac4c, 0xef1a2e6d, 0xae6d9426,
  0xadc1965b, 0x6613ba46, 0xc1fb41c2, 0xbd9b0ecd, 0xbe3dedfc, 0x7989c8ee, 0x6468fd6e, 0x6c0df032, 0xa7cd6634, 0x2c826d8b, 0x2bd2e412, 0x4d4a2dbe, 0xb4bf6fa7, 0xcc1a8959, 0x08263282, 0x51097330,
  0x46e46cb0, 0xdf577ec2, 0x0bd1e364, 0x262c5564, 0x18dda0c9, 0xfe7b45d9, 0xd2ce21c9, 0xd268409a, 0xb1e049e1, 0x200bfa47, 0x512d6e73, 0xc3851eee, 0xf341c081, 0x7d973e48, 0x08d17554, 0xa9e20d28,
  0x70518ce6, 0x203ac303, 0x61add0ab, 0x35d0430c, 0xc3f8e892, 0x0d1c8509, 0xcb92388e, 0x095436bf, 0x2fd6e208, 0x68a29af9, 0x7d61330b, 0x753ec6fc, 0x7211efea, 0x7cd15133, 0xa574c4ff, 0xcb41f198,
  0xb598eef6, 0xebbe7347, 0xc1332568, 0xceba5a70, 0x46a99459, 0xb4ad9f11, 0xae00feaa, 0x00b8b573, 0xa7b480b6, 0xb5f0b06c, 0x29a0ec27, 0xa4daa010, 0x1e76a1c5, 0x74be9133, 0x7f94c950, 0xc61f6ed6,
  0xf5b1c7a1, 0x92e195f8, 0x572384d4, 0xe0732c88, 0x95d41b68, 0xcee496c3, 0x394bbd52, 0x048cd47c, 0xc05309be, 0xd23d2d63, 0x414de9c5, 0xd2229f23, 0x818666a3, 0xf0a8b109, 0xb2f6b127, 0x69a48341,
  0xe4123c56, 0x6c548c8f, 0xf5941f61, 0x94b993aa, 0x8c165134, 0x2876763c, 0x237ce42e, 0xc300d11b, 0x263821ca, 0x3aeb8202, 0x41ec0f84, 0xcf4ac36d, 0xd7393ee6, 0xfd0fc06a, 0x4118a30a, 0x551b54a4,
  0xd074f86f, 0x4cc1c54a, 0x3e57a703, 0x03774cda, 0xede43895, 0x379ce627, 0x59988939, 0xe8490ddc, 0x325410e1, 0xd9352f6a, 0x4047080a, 0xf47c081d, 0x9db51a85, 0xc765d71f, 0x79297527, 0xfcca2773,
  0x5a065b97, 0x114dee4f, 0xd4b12f5f, 0xcb29360a, 0x95d3de16, 0x983162a8, 0x8cbaafb3, 0xbb98b27f, 0xeacd3439, 0xb1fac842, 0x492cbef1, 0xae08ab78, 0xc1d7dfd0, 0x646f1d40, 0xc0f463c4, 0x8fc23a81,
  0x6164e623, 0x3543f2bc, 0x915cc253, 0x8701d0df, 0x136b2fdd, 0x677a359e, 0x0dcfacd0, 0x5a4ea31e, 0x87e25935, 0x97c34e42, 0xc77780f0, 0x5b396fba, 0xef1b52e6, 0xf7080941, 0x2141888b, 0x278946b0,
  0x919e6d64, 0x6518b459, 0x7829fc22, 0x6325d30e, 0x030c0399, 0xba19b463, 0x564dab75, 0x63794f97, 0x2984c787, 0xed702bbe, 0xcb563b4d, 0x6fa56696, 0x4fabc9ed, 0xdcd87a48, 0x874df295, 0x9ecfe9f0,
  0x2a67f49f, 0x1e9aa4e1, 0x9a1b7d08, 0x78d22934, 0x43521602, 0x5718a361, 0xa771ba44, 0x87a3b97c, 0xb0705c82, 0xb7526048, 0xbf86dcd7, 0xfd066ea4, 0x7356b1bb, 0xb872426d, 0x1575515d, 0xe99eadb3,
  0x3a9e3c0f, 0x8168599c, 0xe9d07a32, 0x8eeab382, 0x27023ee8, 0x80d10fac, 0xd368bdc2, 0x7664b5a7, 0x89d0cf46, 0x8bed7368, 0xff02af49, 0x7294e430, 0x14034fbb, 0xdabd4cc4, 0x71535cf8, 0x9aaeea20,
  0x1b4d989d, 0x7fa09780, 0xf63ef3d2, 0xfadc6788, 0x012fb568, 0x08c904fa, 0xc660883f, 0xfa1cce2a, 0xd13ac8b8, 0x5cf9c9b3, 0xde62c6bd, 0xadf500ad, 0x159d967e, 0x58a2c06c, 0x665827cb, 0xdb1aa208,
]);
export async function* gear17Chunking(blob: Blob): AsyncGenerator<Chunk> {
  const reader = blob.stream().getReader();
  let hash = 0;
  let readStart = 0;
  let chunkStart = 0;
  for(;;) {
    const { value } = await reader.read();
    if(!value) break;
    for(let i = 0; i < value.byteLength; ++i) {
      hash = (hash << 1) + gear17Table.at(value.at(i)!)!;
      if((hash & 0xFFFF8000) == 0) {
        hash = 0;
        const chunkEnd = readStart + i + 1;
        yield {
          size: chunkEnd - chunkStart,
          hash: await crypto.subtle.digest('SHA-256', await blob.slice(chunkStart, chunkEnd).arrayBuffer()),
        };
        chunkStart = chunkEnd;
      }
    }
    readStart += value.byteLength;
  }
  if(chunkStart < readStart) {
    const chunkEnd = readStart;
    yield {
      size: chunkEnd - chunkStart,
      hash: await crypto.subtle.digest('SHA-256', await blob.slice(chunkStart, chunkEnd).arrayBuffer()),
    };
  }
};
