import {
  List as IList,
} from 'immutable';
import {
  Accessor,
  Component,
  For,
  JSXElement,
  Match,
  Setter,
  Switch,
  createContext,
  createMemo,
  createSignal,
  onCleanup,
  onMount,
  useContext,
} from "solid-js";
import { Dynamic } from "solid-js/web";

export type NavigateOptions = {
  replace?: boolean;
  scroll?: boolean;
};

export type AppPageInfo = {
  title: Accessor<string | undefined>;
  breadcrumb: Accessor<JSXElement | undefined>;
  breadcrumbRoot?: boolean; // do not display all previous breadcrumbs in hierarchy
  uiClass: string;
};

export const RoutingContext = createContext<{
  readonly path: Accessor<string[]>;
  readonly level: number;
  readonly hierarchy: Accessor<IList<AppPageInfo>>;
  readonly setHierarchy: Setter<IList<AppPageInfo>>;
  readonly navigate: (path: string, options?: NavigateOptions) => void;
}>();

export const Router: Component<{
  children: any;
}> = (props) => {
  const [location, setLocation] = createSignal(window.location, {
    equals: false,
  });
  const path = createMemo(() => location().pathname.slice(1).split('/').map(decodeURIComponent));
  const [hierarchy, setHierarchy] = createSignal(IList<AppPageInfo>());
  const navigate = (path: string, options?: NavigateOptions) => {
    if(new URL(path, window.location.href).href == window.location.href) return;
    if(options?.replace) {
      window.history.replaceState(null, '', path);
    } else {
      window.history.pushState(null, '', path);
    }
    setLocation(window.location);
  }
  window.addEventListener('popstate', (e: PopStateEvent) => {
    setLocation(window.location);
  });
  return (
    <RoutingContext.Provider value={{
      path,
      level: 0,
      hierarchy,
      setHierarchy,
      navigate,
    }}>{props.children}</RoutingContext.Provider>
  );
};

export const useRoutingMatch = () => {
  const context = useContext(RoutingContext)!;
  return (path?: string) => {
    const pathPieces = context.path();
    if(path === '') {
      return context.level == pathPieces.length || (context.level < pathPieces.length && pathPieces[context.level] == '');
    }
    return context.level < pathPieces.length && (!path || pathPieces[context.level] == path);
  };
};

export const Routes: Component<{
  routes: {
    path?: string;
    component: Component<{
      route: string;
    }>;
  }[];
  fallback?: Component;
}> = (props) => {
  const context = useContext(RoutingContext)!;
  const routingMatch = useRoutingMatch();
  return (
    <Switch fallback={<Dynamic component={props.fallback} />}>
      <For each={props.routes}>{(route) =>
        <Match when={routingMatch(route.path)}>
          <RoutingContext.Provider value={{
            path: context.path,
            level: context.level + 1,
            hierarchy: context.hierarchy,
            setHierarchy: context.setHierarchy,
            navigate: context.navigate,
          }}>
            <Dynamic component={route.component} route={context.path()[context.level]} />
          </RoutingContext.Provider>
        </Match>
      }</For>
    </Switch>
  );
};

export const useRoutingNavigate = () => {
  const context = useContext(RoutingContext)!;
  return (path: string, options?: NavigateOptions) => context.navigate(path, options);
};

export const Link: Component<{
  href: string;
  children: any;
  class?: string;
}> = (props) => {
  const navigate = useRoutingNavigate();
  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    navigate((e.currentTarget as HTMLAnchorElement).href);
  };
  return (
    <a href={props.href} onClick={onClick} class={props.class}>{props.children}</a>
  );
};

export const AppPage: Component<{
  title?: string;
  breadcrumb?: JSXElement;
  breadcrumbRoot?: boolean;
  uiClass: string;
}> = (props) => {
  const context = useContext(RoutingContext)!;
  onMount(() => {
    context.setHierarchy((hierarchy) => hierarchy.push({
      title: createMemo(() => props.title),
      breadcrumb: createMemo(() => props.breadcrumb),
      breadcrumbRoot: props.breadcrumbRoot,
      uiClass: props.uiClass,
    }));
  });
  onCleanup(() => {
    context.setHierarchy((hierarchy) => hierarchy.pop());
  });
  return null;
};
