import {
  Component,
  createEffect,
  createMemo,
  For,
  Match,
  Show,
  Switch,
  useContext,
} from 'solid-js';
import {
  render,
} from 'solid-js/web';
import {
  AuthContext,
  AuthProvider,
  DialogsPortal,
  Dropdown,
  FaIcon,
  Link,
  LocalizationContext,
  LocalizationProvider,
  LocalizationSelector,
  Router,
  Routes,
  RoutingContext,
  ThemeProvider,
  ThemeSelector,
  useRoutingNavigate,
} from '../components';
import config from '../config';
import {
  AuthWall,
  Oauth,
} from '../dialogs/auth';
import * as localizations from '../localizations';
import { RouteHome } from './routes/home';
import { linkDocs, linkHome } from './routes/links';

const App: Component = () => {
  return (
    <ThemeProvider defaultTheme="system" themes={[
      'system',
      'light',
      'dark',
    ]}>
      <LocalizationProvider dicts={localizations} defaultLang="en">
        <AuthProvider>
          <Router>
            <HeaderPanel />
            <div class="main">
              <AppOauth>
                <AppAuth>
                  <AppSheet />
                </AppAuth>
              </AppOauth>
            </div>
            <FooterPanel />
            <DialogsPortal />
          </Router>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

const AppOauth: Component<{
  children: any;
}> = (props) => {
  return (
    <Routes routes={[
      {
        path: 'oauth',
        component: () =>
          <Routes routes={[
            {
              component: Oauth,
            }
          ]} />,
      },
    ]} fallback={() => props.children} />
  );
};

const AppAuth: Component<{
  children: any;
}> = (props) => {
  const { status: authStatus, authRequired } = useContext(AuthContext)!;

  return (
    <Switch>
      <Match when={authStatus() == 'not_authenticated' && authRequired()}><AuthWall /></Match>
      <Match when={true}>{
        props.children
      }</Match>
    </Switch>
  );
};

const AppSheet: Component = () => {
  const { t } = useContext(LocalizationContext)!;
  const context = useContext(RoutingContext)!;
  const currentPage = createMemo(() => context.hierarchy().last());
  const breadcrumbs = createMemo(() => {
    const breadcrumbs = context.hierarchy().toArray();
    let j = 0;
    for(let i = 0; i < breadcrumbs.length; ++i) {
      const breadcrumb = breadcrumbs[i];
      if(breadcrumb.breadcrumb() == undefined) continue;
      if(breadcrumb.breadcrumbRoot) {
        j = 0;
      }
      if(j < i) {
        breadcrumbs[j] = breadcrumb;
      }
      ++j;
    }
    return breadcrumbs.slice(0, j);
  });
  createEffect(() => {
    const title = currentPage()?.title();
    document.querySelector('title')!.innerText = title ? t('app.title')(title) : t('app.name');
  });
  return (
    <div class={`ui sheet medium ${currentPage()?.uiClass ?? ''}`}>
      <div class="header">
        <div class="breadcrumbs">
          <For each={breadcrumbs()}>{(info, index) =>
            index() > 0
              ? <><FaIcon solid chevron-right={t('dir') == 'ltr'} chevron-left={t('dir') == 'rtl'} weak />{info.breadcrumb()}</>
              : info.breadcrumb()
          }</For>
        </div>
      </div>
      <RouteHome />
    </div>
  );
};

const HeaderPanel: Component = () => {
  const { session, cancelAuth } = useContext(AuthContext)!;
  const { t } = useContext(LocalizationContext)!;
  const navigate = useRoutingNavigate();

  const onHeaderMenu = (key: string) => {
    switch(key) {
    case 'profile':
      navigate('/me');
      break;
    case 'logout':
      cancelAuth();
      break;
    }
  };

  return (
    <div class="header_panel">
      <Link class="app_name" href={linkHome}>{t('app.name')}<sup class="hint">α</sup></Link>
      <div />
      <Show when={session()?.userInfo}>
        <div class="buttons session">
          <Dropdown classList={{
            align_end: true,
          }} title={session()?.userInfo?.title} items={[
            {
              key: 'profile',
              title: t('user.profile'),
            },
            {
              key: 'logout',
              title: t('user.logout'),
            },
          ]} onSelect={onHeaderMenu} alignEnd />
        </div>
      </Show>
    </div>
  );
};

const FooterPanel: Component = () => {
  const { t, lang } = useContext(LocalizationContext)!;

  return (
    <div class="footer_panel">
      <div class="controls">
        <ThemeSelector />
        <LocalizationSelector />
      </div>
      <div class="social_links">
        <div><a href={linkDocs(lang())}>{t('route.docs')}</a></div>
        <For each={config.socialLinks}>{(socialLink) =>
          <a href={socialLink.link} target="_blank">{t(`social_links.${socialLink.title}`)}</a>
        }</For>
      </div>
    </div>
  );
};

render(() => <App />, document.body);
