import {
  Component,
  Show,
  useContext,
} from 'solid-js';
import {
  AppPage,
  AuthContext,
  FaIcon,
  Link,
  LocalizationContext,
  PageNotFound,
  Routes,
} from '../../components';
import { RouteExperiment } from './experiment';
import { RouteInvite } from './invite';
import {
  linkHome,
  linkMe,
  linkProjects,
  linkStore,
} from './links';
import { RouteMe } from './me';
import { RouteProjects } from './projects';
import { RouteStore } from './store';

export const RouteHome: Component = () => {
  const { status: authStatus } = useContext(AuthContext)!;
  const { t } = useContext(LocalizationContext)!;

  return <>
    <AppPage
      title={t('route.home')}
      breadcrumb={<Link href={linkHome}>{t('route.home')}</Link>}
      uiClass="home"
    />
    <Routes routes={[
      {
        path: '',
        component: () => <>
          <h1>{t(`app.name`)}<div class="hint">{t('home.header.hint')}</div></h1>
          <div class="links">
            <Show when={authStatus() == 'authenticated'} fallback={
              <Link href={linkProjects}>
                <FaIcon weak inline fa-2xl solid id-card />
                <span class="title">{t('home.auth')}</span>
              </Link>
            }>
              <Link href={linkProjects}>
                <FaIcon weak inline fa-2xl solid briefcase />
                <span class="title">{t('route.projects')}</span>
                <span class="hint">{t('route.projects.hint')}</span>
              </Link>
              <Link href={linkMe}>
                <FaIcon weak inline fa-2xl solid id-card />
                <span class="title">{t('route.me')}</span>
                <span class="hint">{t('route.me.hint')}</span>
              </Link>
            </Show>
          </div>
          <div class="footer">
          </div>
        </>,
      },
      {
        path: 'projects',
        component: RouteProjects,
      },
      {
        path: 'store',
        component: RouteStore,
      },
      {
        path: 'me',
        component: RouteMe,
      },
      {
        path: 'invite',
        component: RouteInvite,
      },
      {
        path: 'experiment',
        component: RouteExperiment,
      },
      {
        component: PageNotFound,
      }
    ]} />
  </>;
};
