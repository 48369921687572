import {
  Accessor,
  Component,
  createEffect,
  createSignal,
  For,
  JSXElement,
  onMount,
  Setter,
} from 'solid-js';
import {
  Portal,
} from 'solid-js/web';
import {
  flip as floatingUIFlip,
  autoUpdate as floatingUIAutoUpdate,
  ReferenceElement as FloatingUIReferenceElement,
  shift as floatingUIShift,
  size as floatingUISize,
} from '@floating-ui/dom';
import { useFloating } from 'solid-floating-ui';

export const makeAwaitableVar = <T,>(): [(request?: () => void) => Promise<T>, (value: T) => void] => {
  let value: T | undefined = undefined;
  let waiters: (() => void)[] = [];
  return [
    (request) => new Promise((resolve) => {
      if(value !== undefined) resolve(value);
      else {
        request?.();
        waiters.push(() => resolve(value!));
      }
    }),
    (newValue) => {
      value = newValue;
      const w = waiters;
      waiters = [];
      for(let i = 0; i < w.length; ++i) {
        w[i]();
      }
    },
  ];
};

// Create semaphore. Returns async function which acquires semaphore,
// in turn returning function freeing semaphore, which ensures it runs once.
export const makeSemaphore = (resourcesCount: number): () => Promise<() => void> => {
  let takenCount = 0;
  const waiters: (() => void)[] = [];

  return async () => {
    let freed = false;
    const free = () => {
      if(freed) return;
      freed = true;
      if(waiters.length > 0) {
        waiters.splice(0, 1)[0]();
      } else {
        --takenCount;
      }
    };
    if(takenCount < resourcesCount) {
      ++takenCount;
    } else {
      await new Promise<void>((resolve) => {
        waiters.push(resolve);
      });
    }
    return free;
  };
};

export const Button: Component<{
  type?: 'button' | 'submit' | 'reset';
  autofocus?: boolean;
  onClick?: any;
  disabled?: boolean;
  title?: string;
  danger?: boolean;
  light?: boolean;
  children: any;
}> = (props) =>
  <button classList={{
    button: true,
    danger: props.danger,
    light: props.light,
  }} type={props.type ?? 'button'} autofocus={props.autofocus} onClick={props.onClick} disabled={props.disabled} title={props.title}>{
    props.children
  }</button>
;

export const Dropdown = <K, T extends {
  key: K;
  title: any;
  danger?: boolean;
}>(props: {
  title: any;
  items: T[];
  fallback?: any;
  onSelect?: (key: K) => any;
  disabled?: boolean;
  classList?: any;
  menuClassList?: any;
  alignEnd?: boolean;
}): JSXElement => {
  const [buttonRef, setButtonRef] = createSignal<FloatingUIReferenceElement>();
  const [menuVisible, setMenuVisible] = createSignal(false);

  return (
    <div ref={setButtonRef} classList={{
      dropdown: true,
      ...props.classList ?? {},
    }}>
      <button class="button title" onClick={() => setMenuVisible(true)} disabled={props.disabled} aria-haspopup={true}>{ props.title }<FaIcon inline solid caret-down weak /></button>
      <ContextMenu<K, T>
        items={props.items}
        fallback={props.fallback}
        baseRef={buttonRef}
        onSelect={props.onSelect}
        menuClassList={props.menuClassList}
        alignEnd={props.alignEnd}
        menuVisible={menuVisible}
        setMenuVisible={setMenuVisible}
      />
    </div>
  );
};

export const ContextMenu = <K, T extends {
  key: K;
  title: any;
  danger?: boolean;
}>(props: {
  items: T[];
  fallback?: any;
  baseRef: Accessor<FloatingUIReferenceElement | undefined>,
  onSelect?: (key: K) => any;
  menuClassList?: any;
  alignEnd?: boolean;
  menuVisible: Accessor<boolean>;
  setMenuVisible: Setter<boolean>;
}): JSXElement => {
  const [menuRef, setMenuRef] = createSignal<HTMLDialogElement>();

  const [availableSize, setAvailableSize] = createSignal<{
    width?: string;
    height?: string;
  }>({});

  const position = useFloating(props.baseRef, menuRef, {
    placement: props.alignEnd ? 'bottom-end' : 'bottom-start',
    whileElementsMounted: floatingUIAutoUpdate,
    middleware: [
      floatingUIShift(),
      floatingUIFlip(),
      floatingUISize({
        apply: ({ availableWidth, availableHeight, elements }) => {
          setAvailableSize({
            width: `${availableWidth}px`,
            height: `${availableHeight}px`,
          });
        },
      }),
    ],
  });

  let opened = false;
  createEffect(() => {
    if(props.menuVisible()) {
      if(!opened) {
        opened = true;
        menuRef()?.showModal();
      }
    } else {
      if(opened) {
        opened = false;
        menuRef()?.close();
      }
    }
  });

  const onSelect = (item: T, e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if(props.onSelect?.(item.key) !== true) {
      props.setMenuVisible(false);
    }
  };

  return (
    <Portal>
      <dialog
        ref={setMenuRef}
        classList={{
          dropdown_menu: true,
          ...props.menuClassList ?? {},
        }}
        style={{
          position: position.strategy,
          left: `${position.x ?? 0}px`,
          top: `${position.y ?? 0}px`,
          'max-width': availableSize()?.width,
          'max-height': availableSize()?.height,
          visibility: props.menuVisible() ? 'visible' : 'collapse',
        }}
        onClick={() => props.setMenuVisible(false)}
        onClose={() => props.setMenuVisible(false)}
        aria-modal={true}
        aria-role="menu"
      >{
        <For each={props.items} fallback={props.fallback}>{
          (item) =>
            <button
              classList={{
                danger: item.danger,
              }}
              onClick={[onSelect, item]}
              aria-role="menuitem"
            >{ item.title }</button>
        }</For>
      }</dialog>
    </Portal>
  );
};

const [dialogs, setDialogs] = createSignal<(() => JSXElement)[]>([]);
export type DialogParams<R> = {
  resolve: (value?: R) => void;
  reject: (reason?: any) => void;
  dialog: (props: any) => JSXElement;
};
export const callDialog = <T extends {
  resolve: any;
  reject: any;
  dialog: (props: any) => JSXElement;
},>(C: Component<T>, props: any) => {
  type R = Parameters<Parameters<typeof C>[0]['resolve']>[0];

  return new Promise<R>((resolve, reject) =>
    setDialogs((dialogs) => {
      const close = () => {
        setDialogs((dialogs) => {
          for(let i = 0; i < dialogs.length; ++i) {
            if(dialogs[i] == self) {
              return dialogs.toSpliced(i, 1);
            }
          }
          return dialogs;
        });
      };
      const onResolve = (value?: R) => {
        close();
        resolve(value);
      };
      const onReject = (reason?: any) => {
        close();
        reject(reason);
      };
      const Wrapper = (props: any): JSXElement => {
        let ref: HTMLDialogElement | undefined;
        onMount(() => {
          ref!.showModal();
        });
        const onKeyDown = (e: KeyboardEvent) => {
          if(e.key == 'Enter') {
            if(props.onDefaultAction) {
              e.preventDefault();
              props.onDefaultAction();
            }
          }
        };
        return (
          <dialog {...props} ref={ref} onKeyDown={onKeyDown} onClose={() => onResolve()} />
        );
      };
      const self = () => <C dialog={Wrapper} resolve={onResolve} reject={onReject} {...props} />;
      return [
        ...dialogs,
        self,
      ];
    })
  );
};
export const DialogsPortal: Component = () =>
  <div class="dialogs">
    <For each={dialogs()}>{ (dialog) => dialog() }</For>
  </div>
;

export const FaIcon: Component<any> = (props) => <span classList={{
  icon: true,
  ...Object.fromEntries(Object.keys(props).map((key) =>
    [{
      inline: true,
      weak: true,
      error: true,
      item_start: true,
      'fa-2xl': true,
    }[key] ? key : `fa-${key}`, props[key]]
  ))
}} aria-hidden={true} />;
