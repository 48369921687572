import {
  Component,
  Show,
  createResource,
  useContext,
} from 'solid-js';
import {
  AppPage,
  AuthContext,
  Button,
  LocalizationContext,
  ResourceFallback,
  useRoutingNavigate,
} from '../../components';
import { showErrorScope } from '../../dialogs/basic';
import { linkProject } from './links';

export const RouteInvite: Component = () => {
  const { api } = useContext(AuthContext)!;
  const { t } = useContext(LocalizationContext)!;
  const navigate = useRoutingNavigate();

  const token = new URL(window.location.href).searchParams.get('token');

  const [inviteInfo] = createResource(async () => {
    if(!token) return null;
    try {
      return await api.getInvites({
        token,
      });
    } catch(e) {
      return null;
    }
  });

  const onJoin = async () => {
    await showErrorScope(async () => {
      const projectId = await api.postInvites({
        token: token!,
      });
      navigate(linkProject(projectId), {
        replace: true,
      });
    });
  };

  return <>
    <AppPage
      title={t('route.invite')}
      breadcrumb={t('route.invite')}
      uiClass="invite"
    />
    <h1>{inviteInfo()?.projectTitle ?? t('route.invite')}<div class="hint">{t('route.invite.hint')}</div></h1>
    <Show when={inviteInfo()} fallback={
      <ResourceFallback resource={inviteInfo} page />
    }>
      <div class="message">{t(inviteInfo()?.isAlreadyMember ? 'invite.message.already_member' : 'invite.message.join')(inviteInfo()?.projectTitle)}</div>
      <div class="footer">
        <Show when={!(inviteInfo()?.isAlreadyMember ?? true)}>
          <div class="buttons">
            <Button onClick={onJoin}>{t('invite.join')}</Button>
          </div>
        </Show>
      </div>
    </Show>
  </>;
};
